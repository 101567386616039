import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[3]};
  background: ${({ theme, contentBgColor }) =>
    contentBgColor
      ? theme.colors[contentBgColor] || contentBgColor
      : theme.colors.gray1};
  max-width: ${({ showBigImage }) => (showBigImage ? '331px' : 'none')};
  ${({ theme }) => theme.media.mobile} {
    max-width: none;
  }
`;
