const Bookmark = ({ width, height, color, stroke, ...props }) => (
  <svg
    width={width || '27'}
    height={height || '29'}
    viewBox="0 0 27 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.1666 27L13.4999 18.6667L1.83325 27V0.333333C1.83325 -0.550722 2.18444 -1.39857 2.80956 -2.02369C3.43468 -2.64881 4.28253 -3 5.16659 -3H21.8333C22.7173 -3 23.5652 -2.64881 24.1903 -2.02369C24.8154 -1.39857 25.1666 -0.550722 25.1666 0.333333V27Z"
      fill={color || 'white'}
      stroke={stroke || 'black'}
      strokeWidth="3.33862"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bookmark;
