import React, { useRef, useEffect, useCallback, useState } from 'react';

import * as S from './style';
import Icon from '../Icon';
import * as T from '../Typography';
import QuestionForm from './QuestionForm';
import PopularQuestions from './PopularQuestions';
import ThankYou from './ThankYou';

const Routing = ({
  showForm,
  setShowForm,
  showThankyou,
  setShowThankyou,
  setOpen,
}) => {
  if (showForm && !showThankyou) {
    return <QuestionForm setShowThankyou={setShowThankyou} />;
  } else if (showThankyou) {
    return <ThankYou setOpen={setOpen} />;
  }
  return <PopularQuestions setShowForm={setShowForm} />;
};

const QuestionModal = ({ open, setOpen, bgColor }) => {
  const modalRef = useRef();
  const [showForm, setShowForm] = useState(false);
  const [showThankyou, setShowThankyou] = useState(false);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setOpen(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && open) {
        setOpen(false);
      }
    },
    [setOpen, open]
  );

  useEffect(() => {
    document?.addEventListener('keydown', keyPress);
    return () => document?.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => (document.body.style.overflow = 'auto');
  }, [open]);

  return (
    <>
      {open ? (
        <S.Background onClick={closeModal} ref={modalRef}>
          <S.Wrapper>
            <S.ModalWrapper open={open} bgColor={bgColor}>
              <S.CloseModalButton onClick={() => setOpen(false)}>
                <Icon icon="close" width="18" height="18" color="black" />
              </S.CloseModalButton>
              <S.Container mt="7">
                <T.H2 override="h5" color="gray">
                  {showThankyou ? 'Thank you!' : 'Ask our Digital Champions'}
                </T.H2>
                {showThankyou ? (
                  <T.BodyR mt="3" color="gray">
                    Your question has been submitted
                  </T.BodyR>
                ) : (
                  <>
                    <T.BodyR mt="3" color="gray">
                      To help you find out how you can use digital to help with
                      your Scouts tasks, our Digital Champions are here to
                      answer any questions you have.
                    </T.BodyR>
                    <T.BodyR mt="3" color="gray">
                      Let’s start by looking for a similar question
                    </T.BodyR>
                    <T.BodyR mt="3" color="gray">
                      Type your question here
                    </T.BodyR>
                  </>
                )}
              </S.Container>
              <Routing
                showForm={showForm}
                setShowForm={setShowForm}
                showThankyou={showThankyou}
                setShowThankyou={setShowThankyou}
                setOpen={setOpen}
              />
            </S.ModalWrapper>
          </S.Wrapper>
        </S.Background>
      ) : null}
    </>
  );
};

export default QuestionModal;
