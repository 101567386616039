const QuestionMark = ({ width, height, color, ...props }) => (
  <svg
    width={width || '28'}
    height={height || '41'}
    viewBox="0 0 28 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9696 27.4578V24.3852C10.9696 22.8299 11.2541 21.3885 11.8231 20.0608C12.3921 18.7331 13.3594 17.3675 14.725 15.964C15.6354 15.0157 16.2613 14.2191 16.6027 13.5742C16.982 12.9293 17.1717 12.2465 17.1717 11.5258C17.1717 9.74293 15.9199 8.8515 13.4163 8.8515C10.0782 8.8515 6.8728 10.0085 3.8002 12.3224L0.5569 4.6978C2.30183 3.3322 4.40713 2.2511 6.8728 1.4545C9.33847 0.657897 11.7852 0.259598 14.2129 0.259598C16.8303 0.259598 19.1253 0.676865 21.0978 1.5114C23.1083 2.34593 24.6635 3.52186 25.7636 5.0392C26.8637 6.55653 27.4137 8.30147 27.4137 10.274C27.4137 11.981 27.0154 13.5742 26.2188 15.0536C25.4601 16.4951 24.0756 17.9555 22.0651 19.4349C20.2822 20.7246 19.0684 21.8626 18.4235 22.8489C17.8166 23.8352 17.4372 24.7456 17.2855 25.5801L16.9441 27.4578H10.9696ZM8.6936 41V30.6442H19.2201V41H8.6936Z"
      fill={color || 'white'}
    />
  </svg>
);

export default QuestionMark;
