import { useEffect, useState } from 'react';
import * as S from './style';
import * as T from '../../Typography';
import IconWithTextButton from '../../IconWithTextButton';
import { navRoutes } from '../../../constants';
import { skillAreaHeaderImages } from '../../../helpers';
import { skillAreasCodes } from '../../../constants/data-types';
import Icon from '../../Icon';

const RecommendedSkillAreaCard = ({
  image,
  skillId,
  userId,
  completedSkillsNumber,
  totalSkillsNumber,
  skillAreaTitle,
  skillAreaDescription,
  code,
  icon,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(
    skillAreaHeaderImages(skillId - 1, true)
  );

  useEffect(() => {
    const img = new Image();
    const bigImg = skillAreaHeaderImages(image - 1);
    img.src = bigImg;
    img.onload = () => {
      setImgSrc(bigImg);
    };
  }, [image]);

  const isCompleted = completedSkillsNumber === totalSkillsNumber;

  const temp_icons = {
    computer: 'checklist',
    finance: 'calculator',
  };

  const colours = ['blue', 'navy', 'purple', 'teal'];
  const bgColor = colours[(skillId - 1) % colours.length];

  return (
    <S.Wrapper {...props}>
      <S.IconWrapper bg={bgColor}>
        <Icon width={100} icon={temp_icons[icon]} color="white" />
      </S.IconWrapper>
      <S.InnerWrapper>
        <S.ContentWrapper>
          <S.GrayDiv>
            <T.Body16B ml="1" color="gray" ta="center">
              {completedSkillsNumber} of {totalSkillsNumber} <br />
              skills
              <br /> completed
            </T.Body16B>
          </S.GrayDiv>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <T.H3 override="h6" mt="3" ml="4" mr="3" mb="2">
            {skillAreaTitle}
          </T.H3>
          {skillAreaDescription && (
            <T.BodySM mt="2" ml="3">
              {skillAreaDescription}
            </T.BodySM>
          )}

          <IconWithTextButton
            mt="2"
            ml="4"
            mb="3"
            text={
              userId
                ? isCompleted
                  ? 'View more'
                  : 'Start learning'
                : 'Sign up to start learning'
            }
            url={
              userId
                ? navRoutes.GENERAL.SKILL_AREA.replace(':id', skillId)
                : navRoutes.VOLUNTEER.SIGNUP
            }
            icon="rightRow"
            color="gray"
          />
        </S.ContentWrapper>
      </S.InnerWrapper>
      {!isCompleted && (
        <S.FooterWrapper>
          <T.Body16B color="white" ta="center">
            {totalSkillsNumber - completedSkillsNumber} skills left to earn{' '}
            {skillAreasCodes.BASICS === code ? 'Basics ' : 'Expert '}
            award
          </T.Body16B>
        </S.FooterWrapper>
      )}
    </S.Wrapper>
  );
};

export default RecommendedSkillAreaCard;
