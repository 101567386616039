import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as T from '../Typography';
import Icon from '../Icon';

import * as S from './style';

function BackLink({
  color = 'liteGray',
  iconColor = 'liteGray',
  mb,
  customLink,
  text = 'Back',
  bgColor,
  inBar = false, // for old layouts
}) {
  const history = useHistory();
  const goBack = () =>
    customLink ? history.push(customLink) : history.goBack();

  return (
    <S.Wrapper mb={mb} bgColor={bgColor} inBar={inBar}>
      <S.Button onClick={goBack}>
        <Icon icon="leftRow" width={20} height={20} color={iconColor} />
        <T.Body16B ml="1" as="span" color={color}>
          {text}
        </T.Body16B>
      </S.Button>
    </S.Wrapper>
  );
}

BackLink.propTypes = {
  color: PropTypes.string,
  iconColor: PropTypes.string,
};

export default BackLink;
