import { useCallback } from 'react';
import { Slider as AntdSlider } from 'antd';

import Icon from '../../Icon';
import * as T from '../../Typography';

import * as S from './style';
import * as CS from '../style';

const Slider = ({
  label,
  helper,
  isOptional,
  error,
  color,
  disabled,
  options = [],
  showAnswer,
  handleChange,
  selected,
  big,
  ...props
}) => {
  const decideColor = () => {
    if (error) return 'error';
    return color;
  };

  const optionObj = options.reduce((acc, post, i) => {
    return { ...acc, [i]: '' };
  }, {});

  /* commented according to issue #260 */

  // const decideTextPosition = () => {
  //   const perc = Math.round((selected.key / (options.length - 1)) * 100);
  //   if (perc === 100) return { left: null, right: 0, align: 'right' };
  //   if (perc === 0) return { left: 0, right: null, align: 'left' };

  //   if (perc > 50)
  //     return { left: null, right: `${100 - perc - 15}%`, align: 'center' };
  //   if (perc < 50)
  //     return { left: `${perc - 15}%`, right: null, align: 'center' };

  //   // else in middle
  //   return { left: '35%', right: null, align: 'center' };
  // };

  const decideIndicatorColor = useCallback(
    (key) => {
      const start = 0;
      const last = options.length - 1;
      const mid = last / 2;
      if (key === start) {
        return { start: 'black', mid: 'liteGray', last: 'liteGray' };
      }

      if (key === mid) {
        return { start: 'liteGray', mid: 'black', last: 'liteGray' };
      }
      if (key === last) {
        return { start: 'liteGray', mid: 'liteGray', last: 'black' };
      }

      if (last % 2 && (key === mid - 0.5 || key === mid + 0.5)) {
        return { start: 'liteGray', mid: 'black', last: 'liteGray' };
      }
      return { start: 'liteGray', mid: 'liteGray', last: 'liteGray' };
    },
    [options.length]
  );

  return (
    <S.Field disabled={disabled} color={color} aria-label={label} {...props}>
      {label && (
        <CS.Label htmlFor={label} m="0" mb={big ? '6' : '5'} ml="0">
          {big ? (
            <T.BodyB mr="1">{label} </T.BodyB>
          ) : (
            <T.Body16B mr="1">{label} </T.Body16B>
          )}
          {helper && <T.Body16>{helper}</T.Body16>}
          {isOptional && (
            <T.Body16 color="liteGray" m="0" mb="1" ml="1">
              (optional)
            </T.Body16>
          )}
        </CS.Label>
      )}
      <S.IndicatorsWrapper>
        <Icon
          icon="sad"
          color={decideIndicatorColor(selected.key).start}
          width="24"
        />
        <Icon
          icon="flat"
          color={decideIndicatorColor(selected.key).mid}
          width="24"
        />
        <Icon
          icon="smile"
          color={decideIndicatorColor(selected.key).last}
          width="24"
        />
      </S.IndicatorsWrapper>
      <AntdSlider
        aria-valuemin={0}
        aria-valuemax={5}
        aria-valuenow={selected.key}
        disabled={disabled}
        marks={optionObj}
        max={options.length - 1}
        tooltipVisible={false}
        onChange={handleChange}
        value={selected.key}
        aria-label={label}
      />
      {/* commented according to issue #260 */}
      {/* {showAnswer && (
        <div
          style={{
            position: 'relative',
            height: '50px',
          }}
        >
          <T.Body16
            color="gray"
            style={{
              left: decideTextPosition().left,
              right: decideTextPosition().right,
              position: 'absolute',
              top: 0,
              width: `30%`,
              textAlign: decideTextPosition().align,
            }}
          >
            {selected.label}
          </T.Body16>
        </div>
      )} */}
      {error && (
        <T.Body16 color="error" m="0" mt="1">
          {error}
        </T.Body16>
      )}
    </S.Field>
  );
};

export default Slider;
