import axios from 'axios';
import handleError from './format-error';

const SKILL_AREAS_BASE = '/skill-areas';

const getHqSkillAreas = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${SKILL_AREAS_BASE}/hq`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSkillAreas = async ({ options, type } = {}) => {
  try {
    const { data } = await axios.get(`${SKILL_AREAS_BASE}`, {
      params: { type },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getHqSkillAreaById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${SKILL_AREAS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getVolunteerSkillAreaById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${SKILL_AREAS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateSkillArea = async ({ id, body, options }) => {
  try {
    const { data } = await axios.patch(`${SKILL_AREAS_BASE}/${id}`, body);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createSkillArea = async ({ body, options }) => {
  try {
    const { data } = await axios.post(`${SKILL_AREAS_BASE}`, body);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getHqSkillAreas,
  getHqSkillAreaById,
  getSkillAreas,
  getVolunteerSkillAreaById,
  updateSkillArea,
  createSkillArea,
};
