import styled from '@emotion/styled';
import { Drawer as AntdDrawer, Button as AntButton } from 'antd';

export const Drawer = AntdDrawer;

export const Button = styled(AntButton)`
  border: none;
  margin: 0;
`;

export const Navbar = styled('nav')`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 72px;
  display: none;

  ${({ fullScreen }) =>
    fullScreen &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  ${({ theme }) => theme.horizontalPaddings};
  padding-left: min(8.3vw, 120px);

  ${({ theme }) => theme.media.menu} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${({ theme }) => theme.spacings[3]};
    padding-right: ${({ theme }) => theme.spacings[4]};
  }

  ${({ theme }) => theme.media.tablet} {
    min-height: ${({ theme }) => theme.spacings[7]};
    height: ${({ theme }) => theme.spacings[7]};
  }
`;

export const BurgerMenu = styled('nav')`
  position: absolute;
  right: ${({ theme }) => theme.spacings[4]};
  top: ${({ theme }) => theme.spacings[3]};
  z-index: 2;
  display: none;
  ${({ theme }) => theme.media.menu} {
    display: block;
  }

  & button {
    background: none;
    & svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  & button:hover,
  & button:focus,
  & button:active,
  & button:focus-visible {
    outline: none;
    color: ${({ theme }) => theme.colors.blue};
    background: none;
    border-color: none;
  }
`;

export const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  & Button:last-child {
    margin-left: ${({ theme }) => theme.spacings[3]};
  }
`;

export const LinkContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
