const Plus = ({ width, height, color, ...props }) => (
  <svg
    width={width || '15'}
    height={height || '15'}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 2.25V12.75"
      stroke={color || '#0061CE'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.125 7.5L1.875 7.5"
      stroke={color || '#0061CE'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Plus;
