import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const Wrapper = styled.button`
  ${setMargin};
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};
  padding: 0;
  outline: none;
  align-items: center;
`;
