import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as T from '../Typography';
import * as S from './style';
import { useAuth } from '../../context/auth';
import { roles } from '../../constants/';
import QuestionModal from '../QuestionModal';
const HelpButton = ({ handleClick, to }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { user } = useAuth();

  const onClick = (e) => {
    if (to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
    setOpen(true);
  };

  const isVolunteerOrLoggedOut = user?.role === roles.VOLUNTEER || !user?.id;

  return (
    <>
      <S.Button onClick={onClick} showButton={isVolunteerOrLoggedOut}>
        <T.Body16B color="white">Still need help?</T.Body16B>
      </S.Button>
      {open && <QuestionModal open={open} setOpen={setOpen} />}
    </>
  );
};

export default HelpButton;
