import { useState } from 'react';

import * as S from './style';
import * as T from '../../Typography';
import LevelIndicator from '../../LevelIndicator';
import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import { navRoutes } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Activities } from '../../../api-calls';
import Loading from '../../Loading';
import { handleTimeDisplay, handleClickForAnalytics } from '../../../helpers';
import userRoles from '../../../constants/roles';
import { useHistory } from 'react-router-dom';

const NewActivityCard = ({
  id,
  skillId,
  to,
  href,
  title,
  completionTime,
  difficulty,
  isCompleted,
  bgColor,
  summary,
  description,
  setActivities,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    user,
    user: { assessmentScore },
  } = useAuth();
  const history = useHistory();

  const handleAlreadyKnown = async () => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await Activities.AlreadyKnownActivity({
      activityId: id,
      skillId,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
      // need to handle move the activity to completed activities section
      setLoading(false);
    }
  };

  // const buttonText = `Learn more about ${title.toLowerCase()}`;

  const markActivityAsCompleted = () =>
    setActivities((_) => {
      return {
        ..._,
        data: _.data?.map((a) => {
          if (a.id === id) {
            return {
              ...a,
              status: 'completed',
            };
          }
          return a;
        }),
      };
    });

  const activityCompleteUrl = navRoutes.GENERAL.ACTIVITY_COMPLETED.replace(
    ':skillId',
    skillId || 0
  )
    .replace(':activityId', id)
    .replace(':step', 1);

  const handleCompleteActivity = async () => {
    let error;
    let data;
    if (assessmentScore) {
      const res = await Activities.completeActivity({
        activityId: id,
        skillId,
      });
      error = res.error;
      data = res.data;
    }
    if (data) {
      handleClickForAnalytics({
        category: 'Digital Score',
        action: 'Digital Score Number',
        label: `(${data.totalUserScore} out ${data.currentCompletedSkills} of 100)`,
        priority: 'High',
      });
    }
    if (data && data.currentCompletedSkills > data.prevCompletedSkills) {
      handleClickForAnalytics({
        category: 'Skill completed',
        action: 'Digital Skills Number',
        label: `(number of skills completed ${data.currentCompletedSkills} of 39)`,
        priority: 'High',
      });
    }

    history.push(activityCompleteUrl);
    // setErr(error.message);
    console.log(error);
  };

  return (
    <S.Container>
      <S.Header bgColor={bgColor}>
        <T.H3 override="h6" color="white">
          {title}
        </T.H3>
        <S.HeaderContent>
          <T.Body16B color="white" mr="2" style={{ whiteSpace: 'nowrap' }}>
            Up to {handleTimeDisplay(completionTime)}
          </T.Body16B>
        </S.HeaderContent>
      </S.Header>
      <S.Content>
        <T.Body16>{description || 'N/A'}</T.Body16>
        {summary && summary !== '<p><br></p>' && (
          <>
            <T.Body16B mt="3">What you will learn</T.Body16B>
            <S.RichText mt="3" dangerouslySetInnerHTML={{ __html: summary }} />
          </>
        )}

        <S.ButtonsWrapper>
          <Button
            bgColor="white"
            textColor="black"
            boldLine
            href={href}
            to={to}
            mt="3"
            mb="1"
            textAlign="center"
            external
          >
            {isCompleted ? 'Restart tutorial' : 'Start tutorial'}
          </Button>
          {!isCompleted && (
            <Button
              bgColor="white"
              textColor="black"
              boldLine
              mt="3"
              mb="1"
              textAlign="center"
              disabled={isCompleted}
              title={isCompleted && 'This activity already completed'}
              handleClick={handleCompleteActivity}
            >
              {assessmentScore ? 'Mark as complete' : 'Provide feedback'}
            </Button>
          )}
          {!isCompleted && (
            <S.TextButton
              onClick={() => {
                setOpen(true);
                if (user?.id) {
                  handleAlreadyKnown();
                }
              }}
              disabled={user.role === userRoles.HQ}
            >
              <T.Body16B ta="left">I already know this</T.Body16B>
            </S.TextButton>
          )}
        </S.ButtonsWrapper>
      </S.Content>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          onCloseModal={markActivityAsCompleted}
        >
          <S.ModalContent>
            {loading ? (
              <S.LoadingContainer>
                <Loading />
              </S.LoadingContainer>
            ) : (
              <>
                <S.Circle>
                  <Icon icon="light" color="blue" width="25" height="25" />
                </S.Circle>
                <T.H2 override="h5" mt={4}>
                  {user?.id ? 'Sure thing!' : 'Nice one!'}
                </T.H2>
                <T.BodyR mt={2} mb={4} ta="center" ml={4} mr={4}>
                  {user?.id
                    ? "We'll mark this as done and if you ever want to find it to refresh just go to your Completed Activities section"
                    : 'Set up an account so you can keep track of what you’ve done so far and save other tasks to do'}
                </T.BodyR>
                {error && (
                  <T.BodyR color="red" ta="center" mb="3">
                    {error}
                  </T.BodyR>
                )}
                {user?.id ? (
                  <Button onClick={() => setOpen(false)}>Go back</Button>
                ) : (
                  <>
                    <Button
                      style={{ marginBottom: 12 }}
                      to={navRoutes.VOLUNTEER.SIGNUP}
                    >
                      Set up an account
                    </Button>
                    <Button
                      mb={1}
                      variant="outlined"
                      color="liteGray"
                      handleClick={() => setOpen(false)}
                    >
                      Go back
                    </Button>
                  </>
                )}
              </>
            )}
          </S.ModalContent>
        </Modal>
      )}
    </S.Container>
  );
};
export default NewActivityCard;
