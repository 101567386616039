const TextFile = ({ width, height, color, ...props }) => (
  <svg
    width={width || '120'}
    height={height || '120'}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M117.5 97.5002H115V35.0002C115 33.0111 114.21 31.1034 112.803 29.6969C111.397 28.2904 109.489 27.5002 107.5 27.5002H92.5C92.5 19.4752 92.5 19.7002 92.3 19.0502C92.1 18.4002 93.175 19.6502 76.775 3.22521C75.725 2.20021 79.05 2.50021 30 2.50021C29.337 2.50021 28.7011 2.7636 28.2322 3.23244C27.7634 3.70129 27.5 4.33717 27.5 5.00021V27.5002H12.5C10.5109 27.5002 8.60322 28.2904 7.1967 29.6969C5.79018 31.1034 5 33.0111 5 35.0002V97.5002H2.5C1.83696 97.5002 1.20107 97.7636 0.732233 98.2325C0.263392 98.7013 0 99.3372 0 100L0 105C0 108.315 1.31696 111.495 3.66117 113.839C6.00537 116.183 9.18479 117.5 12.5 117.5H107.5C109.142 117.5 110.767 117.177 112.284 116.549C113.8 115.921 115.178 115 116.339 113.839C117.5 112.678 118.42 111.3 119.048 109.784C119.677 108.267 120 106.642 120 105V100C120 99.3372 119.737 98.7013 119.268 98.2325C118.799 97.7636 118.163 97.5002 117.5 97.5002ZM77.5 11.0252L83.975 17.5002H77.5V11.0252ZM32.5 7.50021H72.5V20.0002C72.5 20.6633 72.7634 21.2991 73.2322 21.768C73.7011 22.2368 74.337 22.5002 75 22.5002H87.5V82.5002H32.5V7.50021ZM10 35.0002C10 34.3372 10.2634 33.7013 10.7322 33.2324C11.2011 32.7636 11.837 32.5002 12.5 32.5002H27.5V85.0002C27.5 85.6633 27.7634 86.2991 28.2322 86.768C28.7011 87.2368 29.337 87.5002 30 87.5002H90C90.663 87.5002 91.2989 87.2368 91.7678 86.768C92.2366 86.2991 92.5 85.6633 92.5 85.0002V32.5002H107.5C108.163 32.5002 108.799 32.7636 109.268 33.2324C109.737 33.7013 110 34.3372 110 35.0002V97.5002C67.75 97.5002 69.75 97.2752 68.7 97.9252C67.65 98.5752 67.725 98.9502 65.95 102.5H54.05L52.225 98.8752C51.225 96.8502 52.225 97.5002 10 97.5002V35.0002ZM115 105C115 106.989 114.21 108.897 112.803 110.304C111.397 111.71 109.489 112.5 107.5 112.5H12.5C10.5109 112.5 8.60322 111.71 7.1967 110.304C5.79018 108.897 5 106.989 5 105V102.5H48.45C50.475 106.525 50.65 107.5 52.5 107.5C71.475 107.5 68.425 108.75 71.55 102.5H115V105Z"
      fill={color || 'white'}
    />
    <path
      d="M42.5 22.5001V20.0001H47.5V35.0001H45C44.337 35.0001 43.7011 35.2635 43.2322 35.7323C42.7634 36.2012 42.5 36.837 42.5 37.5001C42.5 38.1631 42.7634 38.799 43.2322 39.2679C43.7011 39.7367 44.337 40.0001 45 40.0001H55C55.663 40.0001 56.2989 39.7367 56.7678 39.2679C57.2366 38.799 57.5 38.1631 57.5 37.5001C57.5 36.837 57.2366 36.2012 56.7678 35.7323C56.2989 35.2635 55.663 35.0001 55 35.0001H52.5V20.0001H57.5V22.5001C57.5 23.1631 57.7634 23.799 58.2322 24.2679C58.7011 24.7367 59.337 25.0001 60 25.0001C60.663 25.0001 61.2989 24.7367 61.7678 24.2679C62.2366 23.799 62.5 23.1631 62.5 22.5001V17.5001C62.5 16.837 62.2366 16.2012 61.7678 15.7323C61.2989 15.2635 60.663 15.0001 60 15.0001H40C39.337 15.0001 38.7011 15.2635 38.2322 15.7323C37.7634 16.2012 37.5 16.837 37.5 17.5001V22.5001C37.5 23.1631 37.7634 23.799 38.2322 24.2679C38.7011 24.7367 39.337 25.0001 40 25.0001C40.663 25.0001 41.2989 24.7367 41.7678 24.2679C42.2366 23.799 42.5 23.1631 42.5 22.5001Z"
      fill={color || 'white'}
    />
    <path
      d="M65.0002 40.0003H80.0002C80.6633 40.0003 81.2992 39.7369 81.768 39.2681C82.2369 38.7993 82.5002 38.1634 82.5002 37.5003C82.5002 36.8373 82.2369 36.2014 81.768 35.7326C81.2992 35.2637 80.6633 35.0003 80.0002 35.0003H65.0002C64.3372 35.0003 63.7013 35.2637 63.2325 35.7326C62.7636 36.2014 62.5002 36.8373 62.5002 37.5003C62.5002 38.1634 62.7636 38.7993 63.2325 39.2681C63.7013 39.7369 64.3372 40.0003 65.0002 40.0003Z"
      fill={color || 'white'}
    />
    <path
      d="M40 50.0001H80C80.663 50.0001 81.2989 49.7367 81.7678 49.2679C82.2366 48.799 82.5 48.1631 82.5 47.5001C82.5 46.8371 82.2366 46.2012 81.7678 45.7323C81.2989 45.2635 80.663 45.0001 80 45.0001H40C39.337 45.0001 38.7011 45.2635 38.2322 45.7323C37.7634 46.2012 37.5 46.8371 37.5 47.5001C37.5 48.1631 37.7634 48.799 38.2322 49.2679C38.7011 49.7367 39.337 50.0001 40 50.0001Z"
      fill={color || 'white'}
    />
    <path
      d="M40 60.0003H80C80.663 60.0003 81.2989 59.7369 81.7678 59.2681C82.2366 58.7993 82.5 58.1634 82.5 57.5003C82.5 56.8373 82.2366 56.2014 81.7678 55.7326C81.2989 55.2637 80.663 55.0003 80 55.0003H40C39.337 55.0003 38.7011 55.2637 38.2322 55.7326C37.7634 56.2014 37.5 56.8373 37.5 57.5003C37.5 58.1634 37.7634 58.7993 38.2322 59.2681C38.7011 59.7369 39.337 60.0003 40 60.0003Z"
      fill={color || 'white'}
    />
    <path
      d="M40 70.0003H80C80.663 70.0003 81.2989 69.7369 81.7678 69.2681C82.2366 68.7993 82.5 68.1634 82.5 67.5003C82.5 66.8373 82.2366 66.2014 81.7678 65.7326C81.2989 65.2637 80.663 65.0003 80 65.0003H40C39.337 65.0003 38.7011 65.2637 38.2322 65.7326C37.7634 66.2014 37.5 66.8373 37.5 67.5003C37.5 68.1634 37.7634 68.7993 38.2322 69.2681C38.7011 69.7369 39.337 70.0003 40 70.0003Z"
      fill={color || 'white'}
    />
  </svg>
);

export default TextFile;
