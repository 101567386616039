import React from 'react';
import * as S from './style';

import * as T from '../Typography';
import Icon from '../Icon';

const optimizeFileName = (name) => {
  const [fileName, ext] = name.split('.');
  if (ext && fileName.length > 28) {
    return `${fileName.substring(0, 28)}... .${ext}`;
  }
  return name.length > 50 ? `${name.substring(0, 32)}...` : name;
};

const FilesContainer = ({
  filesList = [],
  setFileList,
  setFiles,
  setMaxFilesNumberReached,
  setError,
  showDeleteIcon = true,
}) => {
  const handleDelete = (file) => {
    setFileList((prev) => prev.filter((e) => e.name !== file));
    setFiles((prev) => prev.filter((e) => e.data.name !== file));
    setMaxFilesNumberReached(false);
    setError('');
  };
  return (
    <S.Wrapper mt={2}>
      {filesList.length > 0 &&
        filesList.map((file, index) => (
          <S.InnerWrapper>
            <S.ListItem href={file.url} key={index} download>
              <Icon icon="link" width="28px" height="28px" color="blue" />
              <T.Body16B ml="2" mr={3} as="span" color="blue">
                {file && optimizeFileName(file.name)}
              </T.Body16B>
            </S.ListItem>
            {showDeleteIcon && (
              <Icon
                icon="close"
                width="16px"
                height="16px"
                color="red"
                onClick={() => handleDelete(file.name)}
              />
            )}
          </S.InnerWrapper>
        ))}
    </S.Wrapper>
  );
};

export default FilesContainer;
