const Whatsapp = ({ width, height, color, strokeColor, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.168 14.384C18.095 14.2643 17.9023 14.1927 17.6147 14.0493C17.3257 13.906 15.905 13.212 15.6407 13.117C15.3763 13.022 15.1823 12.974 14.991 13.2603C14.7983 13.5467 14.2447 14.1927 14.0757 14.384C13.9077 14.5753 13.7397 14.599 13.4507 14.4557C13.1613 14.3127 12.2307 14.009 11.1277 13.0337C10.2697 12.2733 9.69001 11.3357 9.52068 11.0493C9.35301 10.763 9.50401 10.608 9.64701 10.465C9.77734 10.3357 9.93634 10.1303 10.0807 9.96367C10.2253 9.79567 10.2733 9.67567 10.3697 9.48433C10.466 9.293 10.418 9.12633 10.345 8.983C10.2733 8.84 9.69534 7.42967 9.45434 6.85533C9.21501 6.28267 8.97401 6.37733 8.80468 6.37733C8.63668 6.37733 8.44401 6.354 8.25134 6.354C8.05868 6.354 7.74601 6.42567 7.48168 6.71233C7.21734 6.99867 6.47134 7.69267 6.47134 9.10167C6.47134 10.5117 7.50534 11.875 7.64968 12.065C7.79434 12.2563 9.64701 15.2447 12.5833 16.3917C15.5197 17.539 15.5197 17.156 16.0497 17.108C16.578 17.061 17.758 16.4153 17.9987 15.746C18.2397 15.0767 18.2397 14.5023 18.168 14.384ZM12.2187 1.862C6.75535 1.862 2.31268 6.27067 2.31268 11.6913C2.31268 13.841 3.01302 15.8333 4.19935 17.4543L2.96235 21.104L6.76835 19.8943C8.33202 20.9203 10.2057 21.5193 12.2187 21.5193C17.6797 21.5193 22.1237 17.1107 22.1237 11.6913C22.1237 6.27067 17.6797 1.862 12.2187 1.862ZM24 11.6913C24 18.147 18.7253 23.3813 12.2187 23.3813C10.1523 23.3813 8.211 22.854 6.52333 21.927L0 24L2.12633 17.7277C1.05333 15.966 0.436333 13.8997 0.436333 11.6913C0.436333 5.23433 5.711 0 12.2187 0C18.7253 0 24 5.23433 24 11.6913Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Whatsapp;
