const Hand = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '24'}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 11.5V14M7.5 11.5V5.5C7.5 4.67157 8.17157 4 9 4C9.82843 4 10.5 4.67157 10.5 5.5M7.5 11.5C7.5 10.6716 6.82843 10 6 10C5.17157 10 4.5 10.6716 4.5 11.5V13.5C4.5 17.6421 7.85786 21 12 21C16.1421 21 19.5 17.6421 19.5 13.5V8.5C19.5 7.67157 18.8284 7 18 7C17.1716 7 16.5 7.67157 16.5 8.5M10.5 5.5V11M10.5 5.5V4.5C10.5 3.67157 11.1716 3 12 3C12.8284 3 13.5 3.67157 13.5 4.5V5.5M13.5 5.5V11M13.5 5.5C13.5 4.67157 14.1716 4 15 4C15.8284 4 16.5 4.67157 16.5 5.5V8.5M16.5 8.5V11"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Hand;
