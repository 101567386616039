import { fields, createSchema, validate as _validate } from '..';

const { requiredText, arrayOfStrings, arrayOfObjects } = fields;

const schema = createSchema({
  title: requiredText,
  skills: arrayOfStrings,
  reply: requiredText,
  files: arrayOfObjects,
});

const validate = (data) => _validate(schema, data);

export default validate;
