import ReactPlayer from 'react-player';
import * as T from '../../Typography';

import * as S from './style';

const VideoCard = ({
  url,
  title,
  content,
  contentBgColor,
  contentColor,
  ...props
}) => {
  return (
    <S.Wrapper {...props}>
      <S.VideoWrapper>
        <ReactPlayer controls url={url} width="100%" height="100%" />
      </S.VideoWrapper>
      <S.ContentWrapper contentBgColor={contentBgColor}>
        <T.H3 override="h6" color={contentColor} mb={2}>
          {title}
        </T.H3>
        <T.Body16 color={contentColor}>{content}</T.Body16>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default VideoCard;
