import React from 'react';
import * as S from './style';
import Icon from '../../Icon';
import * as T from '../../Typography';

const SuggestedActivity = ({ to, title, ...props }) => {
  return (
    <S.Container to={to} {...props}>
      <S.Content>
        <T.BodyB color="gray">{title}</T.BodyB>
      </S.Content>
      <S.IconWrapper>
        <Icon icon="edit" width="19" height="19" color="blue" />
      </S.IconWrapper>
    </S.Container>
  );
};

export default SuggestedActivity;
