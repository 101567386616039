import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  min-height: ${({ isMobile }) => (isMobile ? '50px' : '280px')};
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray1};
  cursor: pointer;
`;

export const IconContainer = styled.div`
  background-color: ${({ theme, color }) => theme.colors[color]};

  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const Content = styled.div`
  display: flex;
  padding: ${({ theme: { spacings } }) => spacings[3]};
  align-items: center;
  gap: ${({ theme: { spacings } }) => spacings[3]};
  margin: auto 0 0 0;
`;

export const Span = styled.span`
  display: block;
`;

export const Div = styled.div`
  display: flex;
`;

export const BookmarkedWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 20px;
`;

export const ContentWrapper = styled(Link)`
  flex: 1;
  display: flex;
  align-content: space-between;
  flex-direction: column;
`;
