const Minus = ({ width, height, color, ...props }) => (
  <svg
    width={width || '10'}
    height={height || '3'}
    viewBox="0 0 10 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="0.177002"
      y1="1.80145"
      x2="9.43167"
      y2="1.80145"
      stroke={color || '#0061CE'}
      strokeWidth="2"
    />
  </svg>
);

export default Minus;
