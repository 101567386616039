import styled from '@emotion/styled';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  background: ${({ bgColor, theme }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.white};
  position: relative;
  z-index: 10;
  padding: ${({ theme }) => theme.spacings[4]};
  width: 100%;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
`;

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CloseModalButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
`;
