import ReactGA from 'react-ga4';

const handleClick = ({
  name = '',
  category = '',
  action = '',
  label = null,
  priority = '',
}) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  ReactGA.event(name, {
    category,
    action,
    label,
    priority,
  });
};

export default handleClick;
