import Button from '../Button';
import * as S from './style';

const ThankYou = ({ setOpen }) => {
  return (
    <S.Container>
      <S.ButtonContainer>
        <Button onClick={() => setOpen(false)}>Return to tool</Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default ThankYou;
