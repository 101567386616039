import * as S from './style';

const FullScreenContent = ({
  children,
  bgColor,
  maxWidth = '1600px',
  ...props
}) => {
  return (
    <S.FullScreenContentWrapper
      bgColor={bgColor}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </S.FullScreenContentWrapper>
  );
};

export default FullScreenContent;
