const CircleCrown = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={width || '16'}
    height={height || '16'}
    {...props}
  >
    <path
      d="M75.37 133.28L133.61 75a58.25 58.25 0 00-58.24 58.28zM70.69 114.84a40.079 40.079 0 1130.54-70.42 1.758 1.758 0 002.909-.755 1.753 1.753 0 00-.639-1.925 43.589 43.589 0 00-71.637 35.77 43.59 43.59 0 0038.417 40.83h.21a1.757 1.757 0 001.77-1.66 1.76 1.76 0 00-1.57-1.85v.01z"
      fill={color || '#fff'}
    />
    <path
      d="M135 76.16l.08-.1a.707.707 0 00.07-.12.832.832 0 00.07-.14l.06-.15v-.14a.482.482 0 000-.12c.005-.06.005-.12 0-.18A60 60 0 0033 32.61 60 60 0 0075.37 135H75.83l.15-.05.13-.06a.403.403 0 00.11-.07l.1-.07.11-.1 58.24-58.24.33-.25zM55.57 92.27v-5.86h39.61v.8a59.6 59.6 0 00-4 3.65c-.47.46-.92.93-1.36 1.41H55.57zM129.2 77l-51.9 51.9A56.581 56.581 0 01129.2 77zM18.89 75a56.48 56.48 0 01112.94-1.73 60.38 60.38 0 00-12.83 1.8 43.655 43.655 0 00-10.29-28.13 1.76 1.76 0 00-2.71 2.24A40.105 40.105 0 01115.45 75v1a59.283 59.283 0 00-15 7.21 1.779 1.779 0 00-1.12-.4h-.44L101 56.2a1.761 1.761 0 00-1.934-1.89 1.76 1.76 0 00-.936.39L88.1 63 76.68 50.2a1.75 1.75 0 00-2.62 0L62.65 63l-10.07-8.3a1.75 1.75 0 00-1.93-.2 1.77 1.77 0 00-.94 1.7l1.77 21.56a1.76 1.76 0 103.5-.29l-1.42-17.41 8.15 6.73a1.75 1.75 0 002.43-.19L75.37 54 86.6 66.6a1.75 1.75 0 002.43.19l8.16-6.73-1.88 22.83H51.47a1.76 1.76 0 100 3.52h.58V94a1.76 1.76 0 001.76 1.76h33a59.59 59.59 0 00-13.13 35.71A56.533 56.533 0 0118.89 75z"
      fill={color || '#fff'}
    />
  </svg>
);

export default CircleCrown;
