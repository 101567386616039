const colors = ['blue', 'navy', 'green'];

/**
 * @author Fadi
 */

const decideColor = (n) => {
  if (n < colors.length) {
    return colors[n];
  } else {
    return decideColor(n - colors.length);
  }
};

export default decideColor;
