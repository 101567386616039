import React, { useEffect, useCallback, useState, useReducer } from 'react';
import debounce from 'lodash.debounce';

import * as S from './style';
import Icon from '../Icon';
import * as T from '../Typography';
import { Row, Col } from '../Grid';
import { BasicInput } from '../Inputs';
import Button from '../Button';
import { Questions } from '../../api-calls';
import Loading from '../Loading';
import { navRoutes } from '../../constants';
const initialState = {
  loading: true,
  data: [],
  type: 'popular',
  offset: 0,
  newDataCount: 0,
  httpError: '',
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const PopularQuestions = ({ setShowForm }) => {
  const [results, setResults] = useReducer(reducer, initialState);
  const [resultInput, setResultInput] = useState('');
  const [showSearchResult, setShowSearchResult] = useState(false);

  const getPopularQuestions = async () => {
    setResults({ loading: true });
    const { data, error } = await Questions.getMostPopularQuestions();
    if (!error) {
      setResults({
        data,
        loading: false,
        httpError: '',
        type: 'popular',
        offset: 0,
        newDataCount: data.length,
      });
    } else {
      setResults({ data: [], loading: false, httpError: error.message });
    }
  };

  useEffect(() => {
    getPopularQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (query) => {
    setResults({ loading: true });
    const { data, error } = await Questions.SearchQuestions({
      question: query,
    });

    if (!error) {
      setResults({
        data,
        type: 'search',
        offset: 0,
        newDataCount: data.length,
        loading: false,
        httpError: '',
      });
      setShowSearchResult(true);
    } else {
      setResults({ data: [], loading: false, httpError: error.message });
    }
  };

  async function d(query) {
    if (query) {
      handleSearch(query);
    } else {
      getPopularQuestions();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeSearch = useCallback(debounce(d, 500), []);

  const handleSearchClick = () => {
    executeSearch(resultInput);
  };

  const handleChange = (value) => {
    setResultInput(value);
  };
  return (
    <>
      <S.InputContainer>
        <Row>
          <Col w={[4, 12, 12]} style={{ padding: 24, paddingBottom: 8 }}>
            <BasicInput
              placeholder="Search for a question"
              aria-label="Search for a question"
              value={resultInput}
              searchFunc={resultInput ? handleSearchClick : null}
              onKeyPress={(e) => {
                if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
                  handleSearchClick();
                }
              }}
              handleChange={({ target: { value } }) => {
                handleChange(value);
              }}
              borderColor={'transparent'}
              showSearchIcon={true}
              showClearIcon={true}
              clearFunc={() => {
                handleChange('');
                getPopularQuestions();
                setShowSearchResult(false);
              }}
            />
          </Col>
        </Row>
      </S.InputContainer>
      <S.Container>
        <T.H3 override="h6" color="black" mt="4">
          {results.loading
            ? 'Loading...'
            : showSearchResult
            ? !!results?.data?.length && 'Top results'
            : 'Most recent questions'}
        </T.H3>
        <S.QuestionsContainer removePadding={!results?.data?.length}>
          {results.loading ? (
            <Loading />
          ) : (
            !!results?.data?.length &&
            results.data.map((item) => {
              return (
                <S.QuestionContainer
                  key={item.id}
                  to={navRoutes.GENERAL.QUESTION_DETAILS.replace(
                    ':id',
                    item.id
                  )}
                >
                  <T.Body16 color="blue" style={{ flex: 1, paddingRight: 16 }}>
                    {item.title || item.text}
                  </T.Body16>
                  <S.IconContainer>
                    <Icon icon="rightRow" width="18" height="18" color="gray" />
                  </S.IconContainer>
                </S.QuestionContainer>
              );
            })
          )}
        </S.QuestionsContainer>
        {results?.httpError && (
          <S.Container>
            <T.Body16 mt="5" color="red">
              {results?.httpError}
            </T.Body16>
          </S.Container>
        )}
        {showSearchResult && (
          <>
            <T.H4 override="h6" color="blackDark" mt="3" mb="4">
              {!results?.data?.length
                ? "Sorry, we can't find a similar question but get in touch and we'll answer it for you"
                : 'Can’t find what you’re looking for?'}
            </T.H4>
            <S.ButtonContainer>
              <Button onClick={() => setShowForm(true)}>
                Leave us a message
              </Button>
            </S.ButtonContainer>
          </>
        )}
      </S.Container>
    </>
  );
};

export default PopularQuestions;
