const handleTimeDisplay = (time) => {
  if (time < 60) {
    return `${time} mins`;
  } else if (time === 60) {
    return `1 hour`;
  } else if (Math.trunc(time / 60) === time / 60) {
    return `${time / 60} hours`;
  } else {
    return `${(time / 60).toFixed(2)} hours`;
  }
};

export default handleTimeDisplay;
