import PropTypes from 'prop-types';
import Icon from '../Icon';

import * as S from './style';

function Loading({ color, fontSize = 48, w, ...props }) {
  return (
    <S.Loading
      indicator={
        <Icon
          icon="spinner"
          width={`${fontSize}px`}
          height={`${fontSize}px`}
          color={color}
        />
      }
      w={w}
    />
  );
}

Loading.defaultProps = {
  color: 'blue',
  fontSize: 48,
};

Loading.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

export default Loading;
