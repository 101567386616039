import axios from 'axios';
import handleError from './format-error';
import { allowedFileTypesAndSizes } from '../constants/data-types';

const MEDIA_BASE = '/media';

const getMediaById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${MEDIA_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSignedURL = async ({
  fileName,
  fileType,
  fileSize,
  fileMaxSize,
  options,
} = {}) => {
  try {
    if (!allowedFileTypesAndSizes.types.includes(fileType)) {
      const error = {
        message: `File ${fileName} is not a ${allowedFileTypesAndSizes.typesNames.join(
          ', '
        )} file`,
      };
      const err = handleError(error, options);

      return { error: err };
    }
    const { data } = await axios.get(`${MEDIA_BASE}/`, {
      params: { fileType, fileName, fileSize, fileMaxSize },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const uploadToS3 = async ({ signedURL, file, options } = {}) => {
  try {
    const {
      config: {
        data: { size, name, type },
      },
    } = await axios.put(signedURL, file, options);
    return { data: { size, name, fileType: type, isNew: true } };
  } catch (error) {
    handleError(error, options);
    return {
      error: {
        error:
          (error.response && error.response.message) || 'Something went wrong',
        message:
          (error.response && error.response.statusText) ||
          'Something went wrong',
        httpStatusCode: (error.response && error.response.status) || 409,
      },
    };
  }
};

const getMediaURL = async ({ key, bucket, bucketRegion, options } = {}) => {
  try {
    const { data } = await axios.get(`${MEDIA_BASE}/url`, {
      params: { key, bucket, bucketRegion },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getMediaById, getSignedURL, uploadToS3, getMediaURL };
