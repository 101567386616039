import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';

export const Container = styled.button`
  ${setMargin};
  width: 100%;
  padding: ${({ theme }) => theme.spacings[3]};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: stretch;
  transition: 0.5s;
  backface-visibility: hidden;

  :hover {
    transform: scale(1.05);
  }
`;

export const Content = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.spacings[2]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings[1]};
`;
