import { fields, createSchema, validate as _validate } from '..';
import { roles } from '../../constants';

const { email, fullName, arrayOfIds } = fields;

const volunteer = createSchema({
  email,
  fullName,
  organisationsIds: arrayOfIds,
});

const HQ = createSchema({
  email,
  fullName,
});

const validate = (data) => {
  if (data.role === roles.VOLUNTEER) {
    return _validate(volunteer, data);
  }
  return _validate(HQ, data);
};

export default validate;
