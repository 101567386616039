import * as T from '../../Typography';
import * as S from './style';
import { format } from 'date-fns';

const SuggestedSkill = ({
  learnerName,
  learnerEmail,
  date,
  text,
  agreeToFollowUp,
}) => {
  return (
    <S.Wrapper>
      {agreeToFollowUp ? (
        <T.Link16
          underline={false}
          href={`mailto:${learnerEmail}`}
          target="_blank"
          color="blue"
        >
          {learnerName}
        </T.Link16>
      ) : (
        <T.Body16B color="blue">{learnerName}</T.Body16B>
      )}

      <T.BodySM color="gray">
        Sent {format(new Date(date), 'dd MMM, yyyy')}
      </T.BodySM>
      <T.Body16 color="gray" mt={2}>
        {text}
      </T.Body16>
    </S.Wrapper>
  );
};

export default SuggestedSkill;
