import axios from 'axios';
import handleError from './format-error';
import { handleValidationError } from '../validation/validate';
import { reviewStatuses } from '../constants/data-types';

const ACTIVITIES_BASE = '/activities';

const getActivityById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getActivitiesBySkillId = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}`, {
      params: { skillId: id },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCompletedActivitiesById = async ({ skillId, activityId }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/completed`, {
      params: { skillId, activityId },
    });
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const getRelatedActivities = async ({ skillId, activityId }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/related`, {
      params: { skillId, activityId },
    });
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const getRelatedActivitiesByUser = async ({ skillId, activityId }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/my-related`, {
      params: { skillId, activityId },
    });
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const getUserActivityProgress = async ({ activityId }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/progress`, {
      params: { activityId },
    });
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const createActivity = async ({ form, options }) => {
  try {
    const { data } = await axios.post(`${ACTIVITIES_BASE}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(err.data);
      return { error: err, inner };
    }
    return { error: err };
  }
};

const updateActivityWithFeedback = async ({ activityId, feedback }) => {
  try {
    const { data } = await axios.patch(
      `${ACTIVITIES_BASE}/feedback`,
      feedback,
      {
        params: { activityId },
      }
    );
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const updateActivity = async ({ id, form, options }) => {
  try {
    const { data } = await axios.patch(`${ACTIVITIES_BASE}/${id}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const completeActivity = async ({ activityId, skillId, options }) => {
  try {
    const { data } = await axios.post(
      `${ACTIVITIES_BASE}/${activityId}/complete`,
      {
        skillId,
      }
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const AlreadyKnownActivity = async ({ activityId, skillId, options }) => {
  try {
    const { data } = await axios.post(
      `${ACTIVITIES_BASE}/${activityId}/already-known`,
      {
        skillId,
      }
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSuggestedActivities = async () => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/suggested`);
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const getSuggestedActivityById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/${id}/suggested`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const approveActivity = async ({ id, form, options }) => {
  try {
    const { data } = await axios.patch(
      `${ACTIVITIES_BASE}/${id}/suggested`,
      form
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteActivity = async ({ id, options }) => {
  try {
    const { data } = await axios.patch(`${ACTIVITIES_BASE}/${id}/suggested`, {
      status: reviewStatuses.DELETED,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getAllActivitiesContent = async () => {
  try {
    const { data } = await axios.get(`${ACTIVITIES_BASE}/all-content`);
    return { data };
  } catch (error) {
    const err = handleError(error);
    return { error: err };
  }
};

const deleteActivityPermanently = async ({ id, options }) => {
  try {
    const { data } = await axios.delete(`${ACTIVITIES_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getActivityById,
  getActivitiesBySkillId,
  getCompletedActivitiesById,
  getRelatedActivities,
  getRelatedActivitiesByUser,
  getUserActivityProgress,
  createActivity,
  updateActivity,
  updateActivityWithFeedback,
  completeActivity,
  getSuggestedActivities,
  getSuggestedActivityById,
  approveActivity,
  deleteActivity,
  AlreadyKnownActivity,
  getAllActivitiesContent,
  deleteActivityPermanently,
};
