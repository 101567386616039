import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import { Link as RouterLink } from 'react-router-dom';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

export const Circle = styled.div`
  background: ${({ theme, circleColor }) =>
    circleColor ? theme.colors[circleColor] || circleColor : theme.colors.blue};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const ContentPart = styled.div`
  margin-left: ${({ theme, noMargin }) => (noMargin ? '0' : theme.spacings[3])};
`;

export const NotesLinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[2]};
  margin-bottom: ${({ theme }) => theme.spacings[2]};
`;

export const NotesLinkText = styled.span`
  margin-left: ${({ theme }) => theme.spacings[2]};
`;

export const NoteHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteTexts = styled.div`
  margin-left: ${({ theme }) => theme.spacings[2]};
`;

export const NameLink = styled(RouterLink)`
  display: inline;
  color: ${({ theme, color }) => `${theme.colors[color]} !important`};
`;

export const RichText = styled.div`
  ${setMargin};
  font-family: 'Nunito Sans';
  font-size: 16px;
  line-height: 143%;

  p {
    font-family: 'Nunito Sans';
    font-size: 16px;
    line-height: 143%;
    pre {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  a {
    font-family: 'Nunito Sans';
    font-size: 16px;
    line-height: 143%;
    color: ${({ theme }) => theme.colors.blue};
    font-style: normal;
    text-decoration-line: underline;
    display: flex;
  }
`;
