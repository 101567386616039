const formatLinksInRichTxt = (richTxt) => {
  const links = richTxt.match(/href="\S*"/g);
  let newTxt = richTxt;
  links?.forEach((link) => {
    let formattedLink = link;
    if (!formattedLink.includes('//')) {
      const arr = formattedLink.split('href="');
      formattedLink = `href="//${arr[1]}`;
    }
    newTxt = newTxt.replace(link, formattedLink);
  });
  return newTxt;
};

export default formatLinksInRichTxt;
