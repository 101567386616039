const Share = ({ width, height, color, ...props }) => (
  <svg
    width={width || '120'}
    height={height || '120'}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M117.5 105H112.5V37.5C112.5 35.5109 111.71 33.6032 110.303 32.1967C108.897 30.7902 106.989 30 105 30H102.5V7.5C102.5 5.51088 101.71 3.60322 100.303 2.1967C98.8968 0.790176 96.9891 0 95 0L25 0C23.0109 0 21.1032 0.790176 19.6967 2.1967C18.2902 3.60322 17.5 5.51088 17.5 7.5V30H15C13.0109 30 11.1032 30.7902 9.6967 32.1967C8.29018 33.6032 7.5 35.5109 7.5 37.5V105H2.5C1.83696 105 1.20107 105.263 0.732233 105.732C0.263392 106.201 0 106.837 0 107.5L0 112.5C0 114.489 0.790176 116.397 2.1967 117.803C3.60322 119.21 5.51088 120 7.5 120H112.5C114.489 120 116.397 119.21 117.803 117.803C119.21 116.397 120 114.489 120 112.5V107.5C120 106.837 119.737 106.201 119.268 105.732C118.799 105.263 118.163 105 117.5 105ZM22.5 25C46.225 25 46.025 25.475 47.075 23.875L51.35 17.5H97.5V92.5C97.5 93.163 97.2366 93.7989 96.7678 94.2678C96.2989 94.7366 95.663 95 95 95H25C24.337 95 23.7011 94.7366 23.2322 94.2678C22.7634 93.7989 22.5 93.163 22.5 92.5V25ZM25 5H95C95.663 5 96.2989 5.26339 96.7678 5.73223C97.2366 6.20107 97.5 6.83696 97.5 7.5V12.5H50C48.275 12.5 48.15 13.25 43.65 20H22.5V7.5C22.5 6.83696 22.7634 6.20107 23.2322 5.73223C23.7011 5.26339 24.337 5 25 5ZM12.5 37.5C12.5 36.837 12.7634 36.2011 13.2322 35.7322C13.7011 35.2634 14.337 35 15 35H17.5V92.5C17.5 94.4891 18.2902 96.3968 19.6967 97.8033C21.1032 99.2098 23.0109 100 25 100H95C96.9891 100 98.8968 99.2098 100.303 97.8033C101.71 96.3968 102.5 94.4891 102.5 92.5V35H105C105.663 35 106.299 35.2634 106.768 35.7322C107.237 36.2011 107.5 36.837 107.5 37.5V105C65 105 69.85 104.125 66.475 107.5H53.525C51.925 105.875 51.325 105 50 105H12.5V37.5ZM115 112.5C115 113.163 114.737 113.799 114.268 114.268C113.799 114.737 113.163 115 112.5 115H7.5C6.83696 115 6.20107 114.737 5.73223 114.268C5.26339 113.799 5 113.163 5 112.5V110H48.975C50.575 111.625 51.175 112.5 52.5 112.5C70.35 112.5 67.825 113.225 71.025 110H115V112.5Z"
      fill={color || 'white'}
    />
    <path
      d="M29.9995 14.9997C30.453 15.0019 30.8987 14.8808 31.2886 14.6492C31.6786 14.4175 31.9982 14.0842 32.2132 13.6848C32.4282 13.2855 32.5305 12.8352 32.5092 12.3821C32.4878 11.9291 32.3436 11.4903 32.0921 11.113C31.8405 10.7356 31.491 10.4338 31.081 10.2398C30.671 10.0459 30.2159 9.96711 29.7646 10.012C29.3133 10.0569 28.8826 10.2237 28.5189 10.4945C28.1551 10.7654 27.8718 11.1302 27.6995 11.5497C27.5439 11.9284 27.4837 12.3396 27.5242 12.7471C27.5647 13.1545 27.7047 13.5458 27.9318 13.8865C28.1589 14.2272 28.4663 14.5069 28.8268 14.701C29.1873 14.8951 29.59 14.9976 29.9995 14.9997Z"
      fill={color || 'white'}
    />
    <path
      d="M41.7746 14.2751C42.2453 13.8044 42.5098 13.1659 42.5098 12.5001C42.5098 11.8344 42.2453 11.1959 41.7746 10.7251C41.3038 10.2544 40.6653 9.98991 39.9996 9.98991C39.3338 9.98991 38.6953 10.2544 38.2246 10.7251C37.7538 11.1959 37.4893 11.8344 37.4893 12.5001C37.4893 12.8298 37.5543 13.1562 37.6804 13.4608C37.8066 13.7653 37.9915 14.042 38.2246 14.2751C38.4577 14.5082 38.7344 14.6931 39.039 14.8193C39.3435 14.9454 39.6699 15.0104 39.9996 15.0104C40.6653 15.0104 41.3038 14.7459 41.7746 14.2751Z"
      fill={color || 'white'}
    />
    <path
      d="M42.5003 54.9996C43.2753 54.9996 42.7503 53.7996 45.0003 63.0996C45.1594 63.7627 45.5754 64.3353 46.1568 64.6916C46.7381 65.048 47.4372 65.1587 48.1003 64.9996C48.7633 64.8405 49.336 64.4245 49.6923 63.8431C50.0486 63.2618 50.1594 62.5627 50.0003 61.8996L48.2003 54.9996C53.4003 54.9996 49.7253 54.1996 66.7003 59.8746C67.0762 59.9986 67.4763 60.0315 67.8674 59.9707C68.2586 59.9098 68.6297 59.7569 68.9503 59.5246C70.4003 58.4996 70.0003 59.2246 70.0003 32.4996C70.0013 32.1021 69.9075 31.7101 69.7267 31.3561C69.5459 31.002 69.2833 30.6962 68.9606 30.464C68.638 30.2318 68.2646 30.0799 67.8715 30.0209C67.4784 29.9619 67.0769 29.9974 66.7003 30.1246C49.1503 35.9746 54.5753 34.9996 42.5003 34.9996C40.5112 34.9996 38.6035 35.7898 37.197 37.1963C35.7905 38.6028 35.0003 40.5105 35.0003 42.4996V47.4996C35.0003 49.4887 35.7905 51.3964 37.197 52.8029C38.6035 54.2094 40.5112 54.9996 42.5003 54.9996ZM65.0003 54.0246L55.0003 50.6996V39.2996L65.0003 35.9746V54.0246ZM40.0003 42.4996C40.0003 41.8366 40.2637 41.2007 40.7325 40.7319C41.2013 40.263 41.8372 39.9996 42.5003 39.9996H50.0003V49.9996H42.5003C41.8372 49.9996 41.2013 49.7362 40.7325 49.2674C40.2637 48.7985 40.0003 48.1627 40.0003 47.4996V42.4996Z"
      fill={color || 'white'}
    />
    <path
      d="M77.5 47.5003H82.5C83.163 47.5003 83.7989 47.2369 84.2678 46.768C84.7366 46.2992 85 45.6633 85 45.0003C85 44.3372 84.7366 43.7013 84.2678 43.2325C83.7989 42.7637 83.163 42.5003 82.5 42.5003H77.5C76.837 42.5003 76.2011 42.7637 75.7322 43.2325C75.2634 43.7013 75 44.3372 75 45.0003C75 45.6633 75.2634 46.2992 75.7322 46.768C76.2011 47.2369 76.837 47.5003 77.5 47.5003Z"
      fill={color || 'white'}
    />
    <path
      d="M75.5995 39.4001C77.0995 39.4001 77.6745 38.3501 80.8995 35.1251C81.2248 34.6502 81.3763 34.0776 81.3284 33.504C81.2805 32.9304 81.0361 32.3909 80.6365 31.9766C80.2369 31.5623 79.7065 31.2986 79.135 31.23C78.5635 31.1614 77.9858 31.2921 77.4995 31.6001L73.9745 35.1251C73.6299 35.4656 73.3915 35.8989 73.2886 36.3723C73.1856 36.8458 73.2225 37.3389 73.3946 37.7918C73.5668 38.2447 73.8668 38.6378 74.2583 38.9233C74.6497 39.2089 75.1157 39.3745 75.5995 39.4001Z"
      fill={color || 'white'}
    />
    <path
      d="M77.4998 58.4C77.9748 58.6642 78.5229 58.7664 79.0612 58.6911C79.5994 58.6159 80.0985 58.3673 80.4828 57.983C80.8671 57.5987 81.1157 57.0996 81.191 56.5613C81.2662 56.0231 81.164 55.4749 80.8998 55L77.4998 51.35C77.0158 50.8659 76.3593 50.594 75.6748 50.594C74.9903 50.594 74.3338 50.8659 73.8498 51.35C73.3658 51.834 73.0939 52.4905 73.0939 53.175C73.0939 53.8595 73.3658 54.5159 73.8498 55L77.4998 58.4Z"
      fill={color || 'white'}
    />
    <path
      d="M32.5 77.5003H55C55.663 77.5003 56.2989 77.2369 56.7678 76.7681C57.2366 76.2992 57.5 75.6633 57.5 75.0003C57.5 74.3372 57.2366 73.7014 56.7678 73.2325C56.2989 72.7637 55.663 72.5003 55 72.5003H32.5C31.837 72.5003 31.2011 72.7637 30.7322 73.2325C30.2634 73.7014 30 74.3372 30 75.0003C30 75.6633 30.2634 76.2992 30.7322 76.7681C31.2011 77.2369 31.837 77.5003 32.5 77.5003Z"
      fill={color || 'white'}
    />
    <path
      d="M32.5 87.5H45C45.663 87.5 46.2989 87.2366 46.7678 86.7678C47.2366 86.2989 47.5 85.663 47.5 85C47.5 84.337 47.2366 83.7011 46.7678 83.2322C46.2989 82.7634 45.663 82.5 45 82.5H32.5C31.837 82.5 31.2011 82.7634 30.7322 83.2322C30.2634 83.7011 30 84.337 30 85C30 85.663 30.2634 86.2989 30.7322 86.7678C31.2011 87.2366 31.837 87.5 32.5 87.5Z"
      fill={color || 'white'}
    />
    <path
      d="M64.9997 77.5003H87.4997C88.1628 77.5003 88.7987 77.2369 89.2675 76.7681C89.7363 76.2992 89.9997 75.6633 89.9997 75.0003C89.9997 74.3372 89.7363 73.7014 89.2675 73.2325C88.7987 72.7637 88.1628 72.5003 87.4997 72.5003H64.9997C64.3367 72.5003 63.7008 72.7637 63.232 73.2325C62.7631 73.7014 62.4997 74.3372 62.4997 75.0003C62.4997 75.6633 62.7631 76.2992 63.232 76.7681C63.7008 77.2369 64.3367 77.5003 64.9997 77.5003Z"
      fill={color || 'white'}
    />
    <path
      d="M77.4997 82.5H64.9997C64.3367 82.5 63.7008 82.7634 63.232 83.2322C62.7631 83.7011 62.4997 84.337 62.4997 85C62.4997 85.663 62.7631 86.2989 63.232 86.7678C63.7008 87.2366 64.3367 87.5 64.9997 87.5H77.4997C78.1628 87.5 78.7987 87.2366 79.2675 86.7678C79.7363 86.2989 79.9997 85.663 79.9997 85C79.9997 84.337 79.7363 83.7011 79.2675 83.2322C78.7987 82.7634 78.1628 82.5 77.4997 82.5Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Share;
