const Notification = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    width={width || '150'}
    height={height || '150'}
    {...props}
  >
    <path
      fill={color || '#1E1E1E'}
      d="M62.667 56H60V20a4 4 0 00-4-4h-1.333V4a4 4 0 00-4-4H13.333a4 4 0 00-4 4v12H8a4 4 0 00-4 4v36H1.333A1.334 1.334 0 000 57.333V60a4 4 0 004 4h56a4 4 0 004-4v-2.667A1.334 1.334 0 0062.667 56zM12 13.333c12.653 0 12.547.254 13.107-.6l2.28-3.4H52v40a1.333 1.333 0 01-1.333 1.334H13.333A1.333 1.333 0 0112 49.333v-36zm1.333-10.666h37.334A1.333 1.333 0 0152 4v2.667H26.667c-.92 0-.987.4-3.387 4H12V4a1.334 1.334 0 011.333-1.333zM6.667 20A1.333 1.333 0 018 18.667h1.333v30.666a4 4 0 004 4h37.334a4 4 0 004-4V18.667H56A1.333 1.333 0 0157.333 20v36c-22.666 0-20.08-.467-21.88 1.333h-6.906c-.854-.866-1.174-1.333-1.88-1.333h-20V20zm54.666 40A1.333 1.333 0 0160 61.333H4A1.333 1.333 0 012.667 60v-1.333H26.12C26.973 59.533 27.293 60 28 60c9.52 0 8.173.387 9.88-1.333h23.453V60z"
    />
    <path
      fill={color || '#1E1E1E'}
      d="M16 8a1.334 1.334 0 10.013-2.667A1.334 1.334 0 0016 8zM22.28 7.613a1.339 1.339 0 10-1.893-1.893 1.339 1.339 0 001.893 1.893zM22.667 29.333c.413 0 .133-.64 1.333 4.32a1.371 1.371 0 002.667-.64l-.96-3.68c2.773 0 .813-.426 9.866 2.6a1.334 1.334 0 001.2-.186c.774-.547.56-.16.56-14.414a1.334 1.334 0 00-1.76-1.266c-9.36 3.12-6.466 2.6-12.906 2.6a4 4 0 00-4 4v2.666a4 4 0 004 4zm12-.52l-5.334-1.773v-6.08l5.334-1.773v9.626zm-13.334-6.146a1.333 1.333 0 011.334-1.334h4v5.334h-4a1.333 1.333 0 01-1.334-1.334v-2.666zM41.333 25.333H44a1.333 1.333 0 000-2.666h-2.667a1.333 1.333 0 100 2.666zM40.32 21.013c.8 0 1.107-.56 2.827-2.28a1.334 1.334 0 00-1.814-1.88l-1.88 1.88a1.333 1.333 0 00.867 2.28zM41.333 31.147a1.334 1.334 0 001.814-1.814l-1.814-1.946a1.376 1.376 0 00-1.946 1.946l1.946 1.814zM17.333 41.333h12a1.333 1.333 0 100-2.666h-12a1.333 1.333 0 100 2.666zM17.333 46.667H24A1.333 1.333 0 1024 44h-6.667a1.334 1.334 0 000 2.667zM34.667 41.333h12a1.334 1.334 0 000-2.666h-12a1.333 1.333 0 100 2.666zM41.333 44h-6.666a1.334 1.334 0 000 2.667h6.666a1.333 1.333 0 100-2.667z"
    />
  </svg>
);

export default Notification;
