import { Dropdown } from '../Inputs';
import { Select } from 'antd';
import Icon from '../Icon';
import * as S from './style';

const { Option } = Select;
const icons = [
  'activityComplete',
  'attach',
  'backup',
  'questionMark',
  'link',
  'heart',
  'circleQuestionMark',
  'bell',
  'bookmark',
  'brokenLamp',
  'calculator',
  'calendar',
  'checklist',
  'circleCrown',
  'circleStar',
  'clock',
  'communication',
  'crown',
  'cupWithFlash',
  'darts',
  'defaultSkill',
  'diamond',
  'digitalTools',
  'discussion',
  'documents',
  'file',
  'knowledge',
  'notification',
  'office',
  'photo',
  'planning',
  'scouts',
  'share',
  'skillComplete',
  'success',
  'textFile',
  'thankYou',
  'trophy',
  'trophyStar',
  'uploadCloud',
  'whatsapp',
  'scoutStack',
];
const SelectIcon = ({ icon, setIcon, error }) => {
  return (
    <>
      <Dropdown
        label="Select Icon"
        name="icon"
        placeholder="Please select"
        options={icons.map((icon) => (
          <Option value={icon} label={icon} key={icon}>
            <S.IconWrapper>
              <Icon
                icon={icon}
                width="40px"
                height="40px"
                color="blue"
                pointer
              />
            </S.IconWrapper>{' '}
          </Option>
        ))}
        margins={{ mb: '4' }}
        selected={{
          label: (
            <S.IconWrapper>
              <Icon
                icon={icon}
                width="40px"
                height="40px"
                color="blue"
                pointer
              />
            </S.IconWrapper>
          ),
        }}
        handleChange={(e) => setIcon(e)}
        error={error}
        customOptions
      />
    </>
  );
};

export default SelectIcon;
