import { fields, createSchema, validate as _validate } from '..';

const {
  arrayOfIds,
  levelField,
  TitleField,
  descriptionField,
  creatorField,
  urlRequired,
  skillTitle,
  skillTask,
  arrayOfStrings,
  requiredText,
  timeFieldMin,
  timeFieldHr,
} = fields;

const suggestActivity = {
  title: TitleField,
  description: descriptionField,
  skills: arrayOfStrings,
  difficulty: levelField,
  resourceLink: urlRequired,
  resourceCreatedBy: creatorField,
  completionTimeHr: timeFieldHr,
  completionTimeMin: timeFieldMin,
  types: arrayOfStrings,
  whatYouWillLearnSummary: requiredText,
  whatYouWillLearnFullDescription: requiredText,
};

const suggestActivityNewSkill = {
  ...suggestActivity,

  skillTitle: skillTitle,
  skillAreas: arrayOfIds,
  skillDescription: descriptionField,
  task1: skillTask,
  task2: skillTask,
  task3: skillTask,
};

const validate = (data) => {
  let schema = createSchema(suggestActivity);
  if (data.skills.includes('addNewSkill')) {
    schema = createSchema(suggestActivityNewSkill);
  }

  return _validate(schema, {
    ...data,
    completionTimeMinClone: data.completionTimeMin,
  });
};

export default validate;
