import React from 'react';
import * as S from './style';
import * as T from '../../Typography';
import IconWithTextButton from '../../IconWithTextButton';

const ContentCard = ({
  image,
  contentBgColor,
  contentColor = 'white',
  title,
  children,
  linkText,
  linkUrl,
  linkIcon,
  showBigImage,
  button,
  reverse,
  isExternal,
  ...props
}) => {
  const Wrapper = showBigImage ? S.WrapperWithImage : S.Wrapper;
  const Title = showBigImage ? T.H2 : T.H3;

  return (
    <Wrapper image={image} {...props}>
      {image && showBigImage && (
        <S.BigImageWrapper image={image} reverse={reverse} />
      )}
      {image && <S.ImgWrapper showBigImage={showBigImage} image={image} />}
      <S.ContentWrapper
        showBigImage={showBigImage}
        contentBgColor={contentBgColor}
      >
        {title && (
          <Title color={contentColor} override={showBigImage ? 'h5' : 'h6'}>
            {title}
          </Title>
        )}
        {typeof children === 'string' ? (
          <T.Body16 color={contentColor} mt={1}>
            {children}
          </T.Body16>
        ) : (
          children
        )}
        {linkUrl && (
          <S.LinkWrapper>
            <IconWithTextButton
              mt="4"
              text={linkText || 'View more'}
              url={linkUrl}
              color={contentColor}
              icon={linkIcon || 'rightRow'}
              isExternal={isExternal}
            />
          </S.LinkWrapper>
        )}
      </S.ContentWrapper>
    </Wrapper>
  );
};

export default ContentCard;
