import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
`;

export const WrapperWithImage = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  min-height: 388px;
  position: relative;
  position: relative;
  height: ${({ height }) => (height ? height : 'auto')};
  padding: ${({ theme }) => theme.spacings[4]};
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    height: auto;
    background-image: none;
    padding: ${({ theme }) => theme.spacings[0]};
    min-height: auto;
  }
`;

export const BigImageWrapper = styled.div`
  position: absolute;
  z-index: -1;
  transform: ${({ reverse }) => reverse && `scaleX(-1)`};
  background-image: ${({ image }) => `url(${image})`};
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const ImgWrapper = styled.div`
  width: 100%;
  background-image: ${({ image }) => `url(${image})`};
  min-height: 204px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: ${({ showBigImage }) => (showBigImage ? 'none' : 'block')};
  max-width: ${({ showBigImage }) => (showBigImage ? '331px' : 'none')};
  ${({ theme }) => theme.media.mobile} {
    display: block;
    max-width: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme, showBigImage }) =>
    showBigImage ? theme.spacings[4] : theme.spacings[3]};
  background: ${({ theme, contentBgColor, showBigImage }) => {
    if (showBigImage) {
      return contentBgColor
        ? `${theme.colors[contentBgColor]}e0` || contentBgColor
        : theme.colors.gray1;
    }
    return contentBgColor
      ? theme.colors[contentBgColor] || contentBgColor
      : theme.colors.gray1;
  }};

  max-width: ${({ showBigImage }) => (showBigImage ? '331px' : 'none')};
  ${({ theme }) => theme.media.mobile} {
    background: ${({ theme, contentBgColor }) =>
      contentBgColor
        ? theme.colors[contentBgColor] || contentBgColor
        : theme.colors.gray1};
    max-width: none;
    padding: ${({ theme }) => theme.spacings[3]};
  }
`;

export const LinkWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`;
