const formatSkills = ({ skillAreas = [], otherSkillAreas = '' }) => {
  const _skillAreas = skillAreas || [];
  const totalSkillAreas = otherSkillAreas
    ? [..._skillAreas, otherSkillAreas]
    : _skillAreas;

  if (totalSkillAreas.length === 1) return totalSkillAreas[0];

  return `${totalSkillAreas
    .slice(0, -1)
    .join(', ')} and ${totalSkillAreas.slice(-1)}`;
};

export default formatSkills;
