const ActivityComplete = ({ width, height, color, ...props }) => (
  <svg
    width={width || '26'}
    height={height || '26'}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0258 19.4413C15.5231 18.744 15.1564 17.9091 14.9943 17.1061H13.6891C13.7893 17.7332 14.0553 18.7683 14.7561 19.8606C14.3171 20.5967 13.5288 21.3237 12.6958 21.7584C12.6952 21.7581 12.6949 21.7581 12.6942 21.7578C12.6939 21.7581 12.6933 21.7581 12.693 21.7584C11.8595 21.3237 11.0715 20.5967 10.6326 19.8606C11.3333 18.7683 11.599 17.7332 11.6994 17.1061H10.394C10.2322 17.9091 9.86554 18.744 9.36281 19.4413L9.14429 19.7452L9.29993 20.0862C9.84547 21.2783 11.1049 22.474 12.4343 23.0609L12.6924 23.1755L12.6942 23.1749L12.6961 23.1755L12.954 23.0609C14.2834 22.474 15.5428 21.2783 16.0885 20.0862L16.2442 19.7452L16.0258 19.4413"
      fill={color || '#1E1E1E'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.35547 15.9712H20.0332V14.7373H5.35547V15.9712Z"
      fill={color || '#1E1E1E'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6997 10.4238C17.9914 11.5116 17.6342 12.7563 17.5229 13.6009H16.2295C16.3377 12.5689 16.7532 11.0573 17.6236 9.72038C18.6583 8.13035 20.0064 7.28895 21.5208 7.28833H21.5303C22.6849 7.28833 23.7658 7.80836 24.4962 8.71541C25.2642 9.66892 25.5548 10.902 25.2936 12.0993L24.0388 11.824C24.2136 11.0226 24.0108 10.1624 23.4967 9.52359C23.0036 8.91219 22.306 8.57554 21.5297 8.57554H21.523C20.1829 8.57632 19.2481 9.58098 18.6997 10.4238"
      fill={color || '#1E1E1E'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4488 13.6013H11.7427C11.5813 12.1082 10.9964 10.8072 10.4784 9.65459C9.96069 8.50492 9.47243 7.41839 9.47944 6.33561C9.49002 5.09066 10.1987 3.82154 11.3752 2.93913C11.5813 2.78367 12.1673 2.33303 12.6941 1.84856C13.2205 2.33303 13.807 2.78367 14.0131 2.93913C15.1894 3.82154 15.8981 5.09066 15.909 6.33561C15.9157 7.41839 15.4276 8.50492 14.91 9.65459C14.3917 10.8072 13.8071 12.1082 13.6457 13.6013H14.9393C15.0991 12.3684 15.5983 11.2588 16.0817 10.1832C16.6581 8.90176 17.2022 7.69127 17.194 6.32594C17.1792 4.68041 16.2783 3.0302 14.7837 1.90968C14.6076 1.77605 13.6907 1.07623 13.164 0.507554L12.6941 0L12.2245 0.507554C11.6974 1.07623 10.7805 1.77605 10.6041 1.90968C9.11009 3.0302 8.20938 4.68041 8.19444 6.32594C8.18588 7.69127 8.73032 8.90176 9.30621 10.1832C9.78979 11.2588 10.2889 12.3684 10.4488 13.6013"
      fill={color || '#1E1E1E'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.68776 10.4241C7.39641 11.5119 7.7533 12.7567 7.8649 13.6012H9.1583C9.05013 12.5693 8.63425 11.0577 7.76419 9.72074C6.72916 8.13072 5.38127 7.28932 3.8667 7.2887H3.85767C2.70279 7.2887 1.62137 7.80872 0.890926 8.71577C0.123286 9.66929 -0.167303 10.9024 0.0935579 12.0996L1.34868 11.8244C1.17358 11.0229 1.37669 10.1628 1.8911 9.52396C2.38387 8.91256 3.08147 8.5759 3.85798 8.5759H3.86467C5.20462 8.57668 6.13973 9.58134 6.68776 10.4241"
      fill={color || '#1E1E1E'}
    />
  </svg>
);

export default ActivityComplete;
