const Close = ({ width, height, color, ...props }) => (
  <svg
    width={width || '45'}
    height={height || '45'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.52391 1.52297L14.0742 14.0733"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14.0737 1.52293L1.52338 14.0733"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Close;
