const Smile = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title  desc"
  >
    <title>Happy emoji</title>
    <desc>This emoji represents a high level of confidence</desc>
    <g clipPath="url(#clip2)">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9964 8.81851 22.731 5.76835 20.4813 3.51869C18.2317 1.26904 15.1815 0.00359958 12 0ZM12 22.4C9.94308 22.4 7.93235 21.79 6.22207 20.6473C4.5118 19.5045 3.17881 17.8803 2.39166 15.9799C1.60451 14.0796 1.39855 11.9885 1.79984 9.97106C2.20112 7.95366 3.19163 6.10056 4.64609 4.64609C6.10056 3.19162 7.95366 2.20112 9.97107 1.79983C11.9885 1.39855 14.0796 1.6045 15.9799 2.39165C17.8803 3.1788 19.5045 4.5118 20.6473 6.22207C21.7901 7.93234 22.4 9.94307 22.4 12C22.3968 14.7573 21.3001 17.4007 19.3504 19.3504C17.4007 21.3001 14.7573 22.3968 12 22.4Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M7.9999 9.5999C8.88356 9.5999 9.5999 8.88356 9.5999 7.9999C9.5999 7.11625 8.88356 6.3999 7.9999 6.3999C7.11625 6.3999 6.3999 7.11625 6.3999 7.9999C6.3999 8.88356 7.11625 9.5999 7.9999 9.5999Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M15.9999 9.5999C16.8836 9.5999 17.5999 8.88356 17.5999 7.9999C17.5999 7.11625 16.8836 6.3999 15.9999 6.3999C15.1162 6.3999 14.3999 7.11625 14.3999 7.9999C14.3999 8.88356 15.1162 9.5999 15.9999 9.5999Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M16.3769 13.6945C16.1898 13.5945 15.9706 13.5731 15.7676 13.6347C15.5646 13.6964 15.3945 13.8361 15.2945 14.0233C14.9761 14.6204 14.5013 15.1197 13.9211 15.4679C13.3408 15.816 12.6768 16 12.0001 16C11.3234 16 10.6594 15.816 10.0791 15.4679C9.49888 15.1197 9.02415 14.6204 8.70571 14.0233C8.65718 13.9289 8.59037 13.8452 8.50916 13.7769C8.42796 13.7086 8.33399 13.6572 8.23272 13.6256C8.13145 13.594 8.0249 13.5828 7.91928 13.5928C7.81366 13.6027 7.71107 13.6336 7.61749 13.6836C7.52391 13.7335 7.4412 13.8016 7.37418 13.8839C7.30716 13.9661 7.25717 14.0608 7.22711 14.1626C7.19706 14.2643 7.18754 14.371 7.19911 14.4765C7.21068 14.5819 7.24311 14.684 7.29451 14.7769C7.74932 15.6298 8.4274 16.343 9.25624 16.8404C10.0851 17.3377 11.0335 17.6005 12.0001 17.6005C12.9667 17.6005 13.9151 17.3377 14.744 16.8404C15.5728 16.343 16.2509 15.6298 16.7057 14.7769C16.8056 14.5897 16.8271 14.3705 16.7654 14.1676C16.7038 13.9646 16.564 13.7944 16.3769 13.6945Z"
        fill={color || '#6E6E6E'}
      />
    </g>
    <defs>
      <clipPath id="clip2">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Smile;
