import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import { NAV_ROUTES } from '../../constants/nav-routes';
import userRoles from '../../constants/roles';
import { useAuth } from '../../context/auth';
import { handleClickForAnalytics } from '../../helpers';

import * as S from './style';

const Menu = ({ closeDrawer, fullScreen, sideBar }) => {
  const { volunteer, HQUser, digitalChampion } = NAV_ROUTES;

  // To test navbar outside of logging in
  // Uncomment this line and comment out the user part in section below
  // const user = { role: userRoles.VOLUNTEER };
  const { user, logout: logoutApi } = useAuth();

  const [navRoutes, setNavRoutes] = useState({
    routes: volunteer.LOGGED_OUT,
    authRoutes: volunteer.LOGGED_OUT_AUTH,
  });
  const [loggingOut, setLoggingOut] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const isHQ = history.location.pathname.includes('/hq');
    if (user.role === userRoles.VOLUNTEER) {
      setNavRoutes({
        routes: volunteer.LOGGED_IN,
        authRoutes: volunteer.LOGGED_IN_AUTH,
      });
    } else if (user.role === userRoles.DIGITAL_CHAMPION) {
      setNavRoutes({
        routes: digitalChampion.LOGGED_IN,
        authRoutes: digitalChampion.LOGGED_IN_AUTH,
      });
    } else if (user.role === userRoles.HQ) {
      setNavRoutes({
        routes: HQUser.LOGGED_IN,
        authRoutes: HQUser.LOGGED_IN_AUTH,
      });
    } else if (!isHQ) {
      setNavRoutes({
        routes: volunteer.LOGGED_OUT,
        authRoutes: volunteer.LOGGED_OUT_AUTH,
      });
    } else if (isHQ) {
      setNavRoutes({
        routes: HQUser.LOGGED_OUT,
        authRoutes: HQUser.LOGGED_OUT_AUTH,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingOut, user]);

  const handleClick = (event, to, logout) => {
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
    if (logout) {
      try {
        logoutApi();
        handleClickForAnalytics({
          name: 'logout',
          category: 'Logout',
          action: `Logout (success)`,
        });
        setLoggingOut(true);
      } catch (err) {
        handleClickForAnalytics({
          name: 'logout',
          category: 'Logout',
          action: `Logout errors`,
        });
        console.error(err);
      }
    }
  };

  const styledTopics = [];
  const styledAuthTopics = [];
  navRoutes.routes.forEach(({ title, to, logout, external }, index) =>
    external
      ? styledTopics.push(
          <S.Li>
            <a
              className="menu__item"
              href={to}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
          </S.Li>
        )
      : styledTopics.push(
          <S.Li>
            <NavLink
              key={index}
              exact
              to={to}
              activeClassName="menu__item__selected"
              className="menu__item"
              external={external}
              onClick={(e) => {
                handleClick(e, to, logout);
                handleClickForAnalytics({
                  name: 'navigation_click',
                  category: 'Navigation_Click',
                  action: title,
                  priority: 'Low',
                });
              }}
            >
              {title}
            </NavLink>
          </S.Li>
        )
  );

  navRoutes.authRoutes.forEach(({ title, to, logout, external }, index) =>
    external
      ? styledAuthTopics.push(
          <S.Li>
            <a
              className="menu__item"
              href={to}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
          </S.Li>
        )
      : styledAuthTopics.push(
          <S.Li>
            <NavLink
              key={navRoutes.routes.length + index}
              exact
              to={to}
              className="menu__item"
              activeClassName={!logout && 'menu__item__selected'}
              external={external}
              onClick={(e) => {
                handleClick(e, to, logout);
                handleClickForAnalytics({
                  name: 'navigation_click',
                  category: 'Navigation_Click',
                  action: title,
                  priority: 'Low',
                });
              }}
            >
              {title}
            </NavLink>
          </S.Li>
        )
  );

  if (fullScreen)
    return (
      <S.FullScreenMenu color="black">
        {styledTopics} <S.FullScreenDivider /> {styledAuthTopics}
      </S.FullScreenMenu>
    );

  return (
    <S.Menu sideBar={sideBar}>
      {styledTopics}
      <S.Li>
        <S.Divider />
      </S.Li>
      {styledAuthTopics}
    </S.Menu>
  );
};

export default Menu;
