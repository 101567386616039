import { navRoutes } from '../../constants';
import { Row, Col } from '../Grid';
import Icon from '../Icon';
import * as T from '../Typography';

import * as S from './style';

const PromoAskQuestion = ({ type, id, skillId }) => {
  return (
    <S.Wrapper
      to={{
        pathname: navRoutes.GENERAL.QUESTION_VIA_SKILL_OR_ACTIVITY,
        state: { type, id, skillId },
      }}
    >
      <S.StyledFullScreenContent bgColor="purple" maxWidth="auto">
        <Row>
          <Col w={[4, 12, 12]}>
            <S.Container>
              <S.TextWrapper>
                <T.Body16 color="white">
                  Want to ask a question or need help?
                </T.Body16>
                <T.Body16B color="white" mt="2">
                  Ask one of our Digital Champions
                </T.Body16B>
              </S.TextWrapper>
              <S.IconWrapper>
                <Icon
                  icon="rightRow"
                  width="24px"
                  height="24px"
                  color="white"
                />
              </S.IconWrapper>
            </S.Container>
          </Col>
        </Row>
      </S.StyledFullScreenContent>
    </S.Wrapper>
  );
};

export default PromoAskQuestion;
