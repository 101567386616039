const Link = ({ width, height, color, ...props }) => (
  <svg
    width={width || '28'}
    height={height || '28'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6666 15.1667C12.1677 15.8365 12.8069 16.3908 13.5409 16.7918C14.275 17.1929 15.0867 17.4313 15.9211 17.4911C16.7554 17.5508 17.5928 17.4305 18.3765 17.1381C19.1602 16.8458 19.8719 16.3883 20.4633 15.7967L23.9633 12.2967C25.0259 11.1965 25.6138 9.72301 25.6006 8.19353C25.5873 6.66405 24.9738 5.20098 23.8922 4.11943C22.8107 3.03789 21.3476 2.4244 19.8181 2.41111C18.2886 2.39782 16.8151 2.98579 15.715 4.04837L13.7083 6.04337"
      stroke={color || '#0061CE'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3333 12.8333C15.8323 12.1635 15.1931 11.6093 14.459 11.2082C13.725 10.8072 12.9132 10.5687 12.0789 10.5089C11.2446 10.4492 10.4071 10.5695 9.62342 10.8619C8.8397 11.1542 8.12802 11.6117 7.53666 12.2033L4.03666 15.7033C2.97407 16.8035 2.3861 18.277 2.39939 19.8065C2.41268 21.336 3.02617 22.799 4.10771 23.8806C5.18926 24.9621 6.65233 25.5756 8.18181 25.5889C9.7113 25.6022 11.1848 25.0142 12.285 23.9516L14.28 21.9566"
      stroke={color || '#0061CE'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Link;
