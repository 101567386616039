import styled from '@emotion/styled';

export const FullScreenContentWrapper = styled.div`
  ${({ theme }) => theme.horizontalPaddings};
  flex: 1;
  padding-top: ${({ pt }) => pt || '32px'};
  padding-bottom: 64px;

  ${({ theme }) => theme.media.mobile} {
    padding-top: 16px;
    padding-bottom: 64px;
  }
`;
