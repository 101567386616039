const Close = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.2547 2.80812C24.1777 2.72345 24.0842 2.65528 23.9801 2.60774C23.8759 2.56019 23.7632 2.53426 23.6488 2.5315C23.5343 2.52874 23.4205 2.54921 23.3142 2.59168C23.2079 2.63415 23.1113 2.69773 23.0302 2.77859L22.3779 3.42775C22.2988 3.50686 22.2544 3.61412 22.2544 3.72596C22.2544 3.8378 22.2988 3.94507 22.3779 4.02418L22.9759 4.62113C23.0151 4.66051 23.0617 4.69176 23.113 4.71309C23.1643 4.73441 23.2193 4.74539 23.2749 4.74539C23.3305 4.74539 23.3855 4.73441 23.4368 4.71309C23.4881 4.69176 23.5347 4.66051 23.5739 4.62113L24.2099 3.98832C24.5316 3.66716 24.5616 3.14404 24.2547 2.80812ZM21.059 4.74611L11.5394 14.2488C11.4817 14.3063 11.4397 14.3777 11.4176 14.4561L10.9773 15.7676C10.9667 15.8032 10.966 15.841 10.9751 15.8769C10.9842 15.9129 11.0029 15.9457 11.0291 15.972C11.0554 15.9982 11.0882 16.0169 11.1242 16.026C11.1602 16.0352 11.198 16.0344 11.2335 16.0239L12.544 15.5836C12.6224 15.5614 12.6938 15.5195 12.7512 15.4617L22.254 5.94107C22.3419 5.85221 22.3912 5.73226 22.3912 5.60726C22.3912 5.48226 22.3419 5.36231 22.254 5.27345L21.7293 4.74611C21.6403 4.65739 21.5198 4.60757 21.3941 4.60757C21.2685 4.60757 21.148 4.65739 21.059 4.74611Z"
      fill={color || '#0061CE'}
    />
    <path
      d="M20.3734 10.2125L13.9456 16.653C13.6972 16.902 13.3918 17.0868 13.056 17.1914L11.6902 17.6486C11.366 17.7402 11.0233 17.7436 10.6974 17.6586C10.3715 17.5736 10.0741 17.4033 9.83595 17.1651C9.59778 16.9269 9.42743 16.6296 9.34244 16.3036C9.25745 15.9777 9.2609 15.635 9.35244 15.3109L9.80965 13.9451C9.91393 13.6093 10.0984 13.304 10.347 13.0554L16.7875 6.6266C16.8465 6.56763 16.8867 6.49248 16.903 6.41065C16.9194 6.32882 16.9111 6.24399 16.8792 6.16688C16.8473 6.08977 16.7932 6.02385 16.7239 5.97746C16.6545 5.93107 16.573 5.90629 16.4895 5.90625H5.48438C4.70116 5.90625 3.95002 6.21738 3.3962 6.7712C2.84238 7.32502 2.53125 8.07616 2.53125 8.85938V21.5156C2.53125 22.2988 2.84238 23.05 3.3962 23.6038C3.95002 24.1576 4.70116 24.4688 5.48438 24.4688H18.1406C18.9238 24.4688 19.675 24.1576 20.2288 23.6038C20.7826 23.05 21.0938 22.2988 21.0938 21.5156V10.5105C21.0937 10.427 21.0689 10.3455 21.0225 10.2761C20.9761 10.2068 20.9102 10.1527 20.8331 10.1208C20.756 10.0889 20.6712 10.0806 20.5893 10.097C20.5075 10.1133 20.4324 10.1535 20.3734 10.2125Z"
      fill={color || '#0061CE'}
    />
  </svg>
);

export default Close;
