import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
  ${setMargin};
  width: 100%;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray1};
  cursor: pointer;
`;

export const ImgWrapper = styled.div`
  width: 100%;
  background-image: ${({ image }) => `url(${image})`};
  min-height: 180px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray1};

  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  min-height: 180px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, bg }) => theme.colors[bg]};
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${({ theme }) => theme.spacings[4]};
  margin-right: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[3]};
  flex: 1;
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const InnerWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  flex: 1;
`;

export const GrayDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 127px;
  background: ${({ theme }) => theme.colors.litestGray};
  border: ${({ theme }) => `1px solid ${theme.colors.literGray}`};
`;
