import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Container = styled('div')`
  padding: ${({ theme: { spacings } }) => `${spacings[3]} `};
  ${({ theme }) => theme.horizontalFooterPaddings};

  width: 100%;
  min-height: ${({ theme }) => theme.constants.layout.footer.height};
  a {
    display: block;
  }
`;

export const Wrapper = styled.div`
  /* padding-top: ${({ theme }) => theme.constants.layout.footer.paddingTop}; */
  display: flex;
  align-items: end;
  background: red;
  background: ${({ theme }) => theme.colors.purple};
`;

export const ExternalLink = styled.a`
  ${setMargin};
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.2px;
  text-transform: ${({ caps }) => (caps ? 'uppercase' : 'initial')};
  color: ${({ theme, color }) =>
    theme.colors[color] || color || theme.colors.blue};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 143%;
  text-decoration-line: underline;
`;

export const ImgWrapper = styled.img`
  width: 80%;
  height: auto;
`;
