// decide text color based on background color
import { colors } from '../theme';

const decideTextColorBasedOnBackground = (bgColor, defaultColor) => {
  switch (bgColor?.toUpperCase()) {
    case 'blue' || colors.blue:
      return 'white';
    case 'purple' || colors.purple:
      return 'white';
    case 'navy' || colors.navy:
      return 'white';
    case 'yellow' || colors.yellow:
      return 'black';
    case 'red' || colors.red:
      return 'black';
    default:
      return defaultColor || 'gray';
  }
};

export default decideTextColorBasedOnBackground;
