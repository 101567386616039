const File = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    width={width || '120'}
    height={height || '120'}
    {...props}
  >
    <g fill={color || '#1E1E1E'} clipPath="url(#clip0)">
      <path d="M63.267 37.48L56 33.84V12a4 4 0 00-4-4H25.16l-.587-.88a4 4 0 00-3.333-1.787H4a4 4 0 00-4 4V44a4 4 0 004 4h37.467A18.385 18.385 0 0051.48 58.56a1.214 1.214 0 00.933 0A18.36 18.36 0 0063.48 43.347l.52-4.56a1.333 1.333 0 00-.733-1.307zM52 10.667A1.333 1.333 0 0153.333 12v1.333H28.72l-1.787-2.666H52zM4 45.333A1.333 1.333 0 012.667 44V9.333A1.333 1.333 0 014 8h17.24a1.334 1.334 0 011.107.6l4.546 6.8A1.333 1.333 0 0028 16h25.333v16.507l-.733-.36h-.093a1.553 1.553 0 00-.267-.08L52 32h-.24c-.091.019-.18.045-.267.08H51.4l-10.667 5.333A1.334 1.334 0 0040 38.747l.413 4.56c.068.68.17 1.356.307 2.026H4zm56.92-2.226A15.627 15.627 0 0152 55.84a15.627 15.627 0 01-8.92-12.733l-.333-3.654L52 34.827l9.253 4.626-.333 3.654z" />
      <path d="M50.667 46.12l-1.72-1.733a1.34 1.34 0 00-1.893 1.893l2.666 2.666a1.332 1.332 0 001.894 0l5.333-5.333a1.34 1.34 0 00-1.893-1.893l-4.387 4.4z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default File;
