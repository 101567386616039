import { fields, createSchema, validate as _validate } from '..';

const {
  arrayOfIds,
  levelField,
  TitleField,
  descriptionField,
  creatorField,
  urlRequired,
  hideActivity,
  isDraft,
  timeFieldMin,
  timeFieldHr,
  optionalFiled,
  arrayOfStringsOptional,
} = fields;

const updateActivity = createSchema({
  title: TitleField,
  description: optionalFiled,
  skills: arrayOfIds,
  difficulty: levelField,
  completionTimeHr: timeFieldHr,
  completionTimeMin: timeFieldMin,
  resourceLink: urlRequired,
  resourceCreatedBy: creatorField,
  whatYouWillLearnSummary: optionalFiled,
  types: arrayOfStringsOptional,
  whatYouWillLearnFullDescription: optionalFiled,
  isDraft,
});

const updateActivityHideActivity = createSchema({
  hideActivity,
});

const createActivity = createSchema({
  title: TitleField,
  description: optionalFiled,
  skills: arrayOfIds,
  difficulty: levelField,
  completionTimeHr: timeFieldHr,
  completionTimeMin: timeFieldMin,
  types: arrayOfStringsOptional,
  whatYouWillLearnSummary: optionalFiled,
  whatYouWillLearnFullDescription: optionalFiled,
  resourceLink: urlRequired,
  resourceCreatedBy: creatorField,
  hideActivity,
  isDraft,
});

const validate = (data) => {
  if (data.new) {
    return _validate(createActivity, {
      ...data,
      completionTimeMinClone: data.completionTimeMin,
    });
  }
  if (data.hideActivity) {
    return _validate(updateActivityHideActivity, {
      ...data,
      completionTimeMinClone: data.completionTimeMin,
    });
  }
  return _validate(updateActivity, {
    ...data,
    completionTimeMinClone: data.completionTimeMin,
  });
};

export default validate;
