const Eye = ({ width, height, color, ...props }) => (
  <svg
    width={width || '17'}
    height={height || '17'}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.892 8.16945C16.7401 7.96169 13.1215 3.08252 8.49992 3.08252C3.87833 3.08252 0.259583 7.9617 0.107877 8.16925C-0.0359591 8.36634 -0.0359591 8.63366 0.107877 8.83076C0.259583 9.03851 3.87833 13.9177 8.49992 13.9177C13.1215 13.9177 16.7401 9.03848 16.892 8.83092C17.036 8.63386 17.036 8.36634 16.892 8.16945ZM8.49992 12.7968C5.09563 12.7968 2.14715 9.5584 1.27434 8.49972C2.14602 7.44011 5.08832 4.20339 8.49992 4.20339C11.904 4.20339 14.8523 7.44123 15.7255 8.50048C14.8538 9.56006 11.9115 12.7968 8.49992 12.7968Z"
      fill={color || '#0061CE'}
    />
    <path
      d="M8.49937 5.13721C6.64524 5.13721 5.13672 6.64573 5.13672 8.49986C5.13672 10.354 6.64524 11.8625 8.49937 11.8625C10.3535 11.8625 11.862 10.354 11.862 8.49986C11.862 6.64573 10.3535 5.13721 8.49937 5.13721ZM8.49937 10.7416C7.26322 10.7416 6.25762 9.73598 6.25762 8.49986C6.25762 7.26374 7.26325 6.25811 8.49937 6.25811C9.73549 6.25811 10.7411 7.26374 10.7411 8.49986C10.7411 9.73598 9.73552 10.7416 8.49937 10.7416Z"
      fill={color || '#0061CE'}
    />
  </svg>
);

export default Eye;
