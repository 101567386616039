import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  ${setMargin};
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[3]};
  background: ${({ theme, contentBgColor }) =>
    contentBgColor
      ? theme.colors[contentBgColor] || contentBgColor
      : theme.colors.gray1};
`;
