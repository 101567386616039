import { fields, createSchema, validate as _validate } from '..';

const { email } = fields;

const notifyMe = createSchema({
  email,
});

const validate = (data) => {
  return _validate(notifyMe, data);
};

export default validate;
