import styled from '@emotion/styled';
import Tick from '../../Icon/icons/Tick.svg';
import setMargin from '../../../helpers/set-margin';

export const Header = styled.div`
  padding: 16px 20px 20px 16px;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.blue};
  width: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacings[3]};
  background: ${({ theme }) => theme.colors.white};
  filter: ${({ theme }) => theme.shadows.card};
  min-height: 180px;
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.liteBlue};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const TextButton = styled.button`
  margin-top: 12px;
  border: none;
  background: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
`;

export const RichText = styled.div`
  ${setMargin};
  font-family: 'Nunito Sans';
  font-size: 16px;
  line-height: 143%;

  p {
    font-family: 'Nunito Sans';
    font-size: 16px;
    line-height: 143%;
    pre {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  a {
    font-family: 'Nunito Sans';
    font-size: 16px;
    line-height: 143%;
    color: ${({ theme }) => theme.colors.blue};
    font-style: normal;
    text-decoration-line: underline;
    display: flex;
  }

  ul {
    margin-top: 8px;
    list-style: none;
  }

  li {
    font-size: 16px;
    list-style-type: none;
    margin-bottom: 8px;
    background: url(${Tick}) no-repeat left top;
    padding: 0px 0 3px 24px;
    background-position: left 4px;
  }
`;

export const LoadingContainer = styled.div`
  height: 266px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  min-width: 270px;
`;
