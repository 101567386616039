import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const commonStyle = ({ bgColor, theme, transparent, type }) => css`
  background: ${type === 'state'
    ? `${theme.colors[bgColor]}`
    : transparent
    ? `${theme.colors[bgColor]}`
    : theme.colors[bgColor]};
  padding: ${theme.spacings[1]} ${theme.spacings[2]};
  flex-basis: auto !important;
  width: fit-content;
  border-radius: 10px;
  margin-right: ${theme.spacings[1]};
  margin-bottom: ${theme.spacings[1]};
`;

export const LinkWrapper = styled(Link)`
  display: block;
  ${commonStyle};
`;

export const TagWrapper = styled.div`
  ${commonStyle};
`;
