import axios from 'axios';
import handleError from './format-error';

const CMS_BASE = '/cms';

const getParentPage = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${CMS_BASE}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export { getParentPage };
