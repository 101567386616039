const Flat = ({ width, height, color, strokeColor, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '24'}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title  desc"
  >
    <title>Flat emoji</title>
    <desc>This emoji represents a medium level of confidence</desc>
    <g clipPath="url(#clip1)">
      <path
        d="M8.4999 9.5999C9.38356 9.5999 10.0999 8.88356 10.0999 7.9999C10.0999 7.11625 9.38356 6.3999 8.4999 6.3999C7.61625 6.3999 6.8999 7.11625 6.8999 7.9999C6.8999 8.88356 7.61625 9.5999 8.4999 9.5999Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M16.4999 9.5999C17.3836 9.5999 18.0999 8.88356 18.0999 7.9999C18.0999 7.11625 17.3836 6.3999 16.4999 6.3999C15.6162 6.3999 14.8999 7.11625 14.8999 7.9999C14.8999 8.88356 15.6162 9.5999 16.4999 9.5999Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M16.4997 14.4H8.49971C8.28753 14.4 8.08405 14.4843 7.93402 14.6343C7.78399 14.7844 7.69971 14.9879 7.69971 15.2C7.69971 15.4122 7.78399 15.6157 7.93402 15.7657C8.08405 15.9157 8.28753 16 8.49971 16H16.4997C16.7119 16 16.9154 15.9157 17.0654 15.7657C17.2154 15.6157 17.2997 15.4122 17.2997 15.2C17.2997 14.9879 17.2154 14.7844 17.0654 14.6343C16.9154 14.4843 16.7119 14.4 16.4997 14.4Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M12.5 0C10.1266 0 7.80655 0.703788 5.83316 2.02236C3.85977 3.34094 2.3217 5.21509 1.41345 7.4078C0.505199 9.60051 0.267559 12.0133 0.730582 14.3411C1.1936 16.6689 2.33649 18.8071 4.01472 20.4853C5.69295 22.1635 7.83115 23.3064 10.1589 23.7694C12.4867 24.2324 14.8995 23.9948 17.0922 23.0866C19.2849 22.1783 21.1591 20.6402 22.4776 18.6668C23.7962 16.6935 24.5 14.3734 24.5 12C24.4964 8.81851 23.231 5.76835 20.9813 3.51869C18.7317 1.26904 15.6815 0.00359958 12.5 0ZM12.5 22.4C10.4431 22.4 8.43235 21.79 6.72207 20.6473C5.0118 19.5045 3.67881 17.8803 2.89166 15.9799C2.10451 14.0796 1.89855 11.9885 2.29984 9.97106C2.70112 7.95366 3.69163 6.10056 5.14609 4.64609C6.60056 3.19162 8.45366 2.20112 10.4711 1.79983C12.4885 1.39855 14.5796 1.6045 16.4799 2.39165C18.3803 3.1788 20.0045 4.5118 21.1473 6.22207C22.2901 7.93234 22.9 9.94307 22.9 12C22.8968 14.7573 21.8001 17.4007 19.8504 19.3504C17.9007 21.3001 15.2573 22.3968 12.5 22.4Z"
        fill={color || '#6E6E6E'}
      />
    </g>
    <defs>
      <clipPath id="clip1">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Flat;
