import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  fullName,
  passwordOptional,
  arrayOfStrings,
  requiredField,
  booleanFiled,
} = fields;

const requestAChampion = createSchema({
  fullName,
  email,
  skillAreas: arrayOfStrings,
  district: requiredField,
  password: passwordOptional,
  receiveEmails: booleanFiled,
});

const validate = (data) => {
  return _validate(requestAChampion, data);
};

export default validate;
