export const DEFAULT_REQUIRED = 'This field is required';
export const INVALID_EMAIL = 'Invalid email';
export const TOO_LONG_MAX_7 = 'Too long - should be 7 characters maximum';
export const TOO_LONG_MAX_50 = 'Too long - should be 50 characters maximum';
export const TOO_LONG_MAX_100 = 'Too long - should be 100 characters maximum';
export const TOO_LONG_MAX_200 = 'Too long - should be 200 characters maximum';
export const TOO_LONG_MAX_140 = 'Too long - should be 140 words maximum';
export const TOO_LONG_MAX_300 = 'Too long - should be 300 characters maximum';
export const TOO_LONG_MAX_500 = 'Too long - should be 500 characters maximum';
export const SHORT_PASSWORD =
  'Password must contain min. 8 characters, one capital letter, one lowercase letter and one number';
export const SHOULD_AGREE_ON_TERMS =
  'You must agree to our terms and conditions';
export const TOO_SHORT_MIN_5 = 'Too short - should be 5 characters minimum';
export const INVALID_POSTCODE = 'Invalid Postcode';
export const NUMBER_REQUIRED = 'You must specify a number';
export const TOO_LONG_WORDS_200 = 'Too long - should be 200 words maximum';
export const TOO_LONG_WORDS_140 = 'Please use 140 words or less';
export const INVALID_LINK = 'Invalid link';
export const SHOULD_ACKNOWLEDGE_OF_SHARE_DATA =
  'You must acknowledge that your details will be shared with other users';
export const INVALID_PHONE = 'Invalid phone number';
