const CircleQuestionMark = ({ width, height, color, ...props }) => (
  <svg
    width={width || '23'}
    height={height || '22'}
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.4133 7.75C8.00817 6.48771 9.6133 5.58333 11.5001 5.58333C13.8933 5.58333 15.8334 7.03841 15.8334 8.83333C15.8334 10.3494 14.4493 11.623 12.5771 11.9821C11.9895 12.0949 11.5001 12.5684 11.5001 13.1667M11.5 16.4167H11.5108M21.25 11C21.25 16.3848 16.8848 20.75 11.5 20.75C6.11522 20.75 1.75 16.3848 1.75 11C1.75 5.61522 6.11522 1.25 11.5 1.25C16.8848 1.25 21.25 5.61522 21.25 11Z"
      stroke={color || '#0061CE'}
      strokeWidth="2.0025"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleQuestionMark;
