import { useHistory } from 'react-router-dom';

import * as T from '../Typography';
import Icon from '../Icon';
import Loading from '../Loading';

import * as S from './style';

const IconWithTextButton = ({
  color = 'white',
  icon = 'rightRow',
  iconSide = 'right',
  url,
  text,
  onClick,
  iconProps = {},
  isExternal,
  loading,
  ...props
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    if (url) {
      if (isExternal) {
        window.open(url, '_blank');
      } else {
        history.push(url);
      }
    }
  };
  return (
    <S.Wrapper onClick={handleClick} loading={loading} {...props}>
      {iconSide === 'left' ? (
        <>
          {loading ? (
            <Loading fontSize={18} />
          ) : (
            <Icon
              icon={icon}
              width={iconProps?.width || 18}
              height={iconProps?.width || 18}
              color={iconProps?.color || color}
              m={iconProps?.m}
            />
          )}
          <T.Body16B ml="2" as="span" color={color}>
            {text}
          </T.Body16B>
        </>
      ) : (
        <>
          <T.Body16B as="span" color={color} mr="2">
            {text}
          </T.Body16B>
          {loading ? (
            <Loading fontSize={18} />
          ) : (
            <Icon
              icon={icon}
              width={iconProps?.width || 18}
              height={iconProps?.height || 18}
              color={iconProps?.color || color}
              m={iconProps?.m}
            />
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default IconWithTextButton;
