import React from 'react';
import * as S from './style';
import * as T from '../../Typography';
import Button from '../../Button';

const ButtonCard = ({
  contentBgColor = 'literGray',
  contentColor = 'gray',
  title,
  children,
  to,
  handleClick,
  btnText,
  ...props
}) => {
  return (
    <S.Wrapper {...props}>
      <S.ContentWrapper contentBgColor={contentBgColor}>
        {title && (
          <T.H3 override="h6" color={contentColor}>
            {title}
          </T.H3>
        )}
        {typeof children === 'string' ? (
          <T.Body16 color={contentColor} mt={1}>
            {children}
          </T.Body16>
        ) : (
          children
        )}
        <Button
          variant="outlined"
          size="small"
          color={contentColor}
          handleClick={handleClick}
          to={to}
          w="auto"
          mt={3}
        >
          {btnText}
        </Button>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default ButtonCard;
