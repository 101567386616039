const CupWithFlash = ({ width, height, color, strokeColor, ...props }) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.0001 21C36.0001 16.2525 35.9926 8.37844 35.9888 6C35.9888 5.60218 35.8308 5.22064 35.5495 4.93934C35.2682 4.65804 34.8867 4.5 34.4888 4.5L13.5085 4.52437C13.1115 4.52437 12.7307 4.68176 12.4495 4.96206C12.1683 5.24235 12.0098 5.62268 12.0085 6.01969C12.0085 8.88656 11.9963 18.1294 11.9963 21C11.9963 27.0262 19.7776 31.5 23.9963 31.5C28.2151 31.5 36.0001 27.0262 36.0001 21Z"
      fill={color || '#E6EFFA'}
    />
    <path
      d="M16.5001 43.5H31.5001M24.0001 43.5V31.5M36.0001 21C36.0001 16.2525 35.9926 8.37844 35.9888 6C35.9888 5.60218 35.8308 5.22064 35.5495 4.93934C35.2682 4.65804 34.8867 4.5 34.4888 4.5L13.5085 4.52437C13.1115 4.52437 12.7307 4.68176 12.4495 4.96206C12.1683 5.24235 12.0098 5.62268 12.0085 6.01969C12.0085 8.88656 11.9963 18.1294 11.9963 21C11.9963 27.0262 19.7776 31.5 23.9963 31.5C28.2151 31.5 36.0001 27.0262 36.0001 21Z"
      stroke={strokeColor || '#0061CE'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 9H43.5V10.5C43.5 15.6769 40.3547 21 36 21M12 9H4.5V10.5C4.5 15.6769 7.64531 21 12 21V9Z"
      stroke={strokeColor || '#0061CE'}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7001 15.4V10.5L18.4001 18.2H23.3001L23.3001 23.1L29.6001 15.4L24.7001 15.4Z"
      stroke={strokeColor || '#0061CE'}
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CupWithFlash;
