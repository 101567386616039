import React from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './style';
import Footer from '../../Footer';
import BackLink from '../../BackLink';
import NavBar from '../NavBar';
import HelpButton from '../../HelpButton';

const FullScreenLayout = ({
  showShare,
  children,
  hideBack,
  showHelpButton,
}) => {
  const location = useLocation();
  return (
    <S.Layout>
      <NavBar fullScreen />
      {!hideBack && location.key && <BackLink inBar bgColor="litestGray" />}

      {children}
      {showHelpButton && <HelpButton />}
      <Footer showShare={showShare} />
    </S.Layout>
  );
};

export default FullScreenLayout;
