const Sad = ({ width, height, color, title, description, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title  desc"
  >
    <title>Sad emoji</title>
    <desc>This emoji represents a low level of confidence</desc>
    <g clipPath="url(#clip0)">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9964 8.81851 22.731 5.76835 20.4813 3.51869C18.2317 1.26904 15.1815 0.00359958 12 0V0ZM12 22.4C9.94308 22.4 7.93235 21.79 6.22207 20.6473C4.5118 19.5045 3.17881 17.8803 2.39166 15.9799C1.60451 14.0796 1.39855 11.9885 1.79984 9.97106C2.20112 7.95366 3.19163 6.10056 4.64609 4.64609C6.10056 3.19162 7.95366 2.20112 9.97107 1.79983C11.9885 1.39855 14.0796 1.6045 15.9799 2.39165C17.8803 3.1788 19.5045 4.5118 20.6473 6.22207C21.7901 7.93234 22.4 9.94307 22.4 12C22.3968 14.7573 21.3001 17.4007 19.3504 19.3504C17.4007 21.3001 14.7573 22.3968 12 22.4Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M7.9999 9.5999C8.88356 9.5999 9.5999 8.88356 9.5999 7.9999C9.5999 7.11625 8.88356 6.3999 7.9999 6.3999C7.11625 6.3999 6.3999 7.11625 6.3999 7.9999C6.3999 8.88356 7.11625 9.5999 7.9999 9.5999Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M15.9999 9.5999C16.8836 9.5999 17.5999 8.88356 17.5999 7.9999C17.5999 7.11625 16.8836 6.3999 15.9999 6.3999C15.1162 6.3999 14.3999 7.11625 14.3999 7.9999C14.3999 8.88356 15.1162 9.5999 15.9999 9.5999Z"
        fill={color || '#6E6E6E'}
      />
      <path
        d="M11.9998 13.6C11.033 13.5991 10.0843 13.8614 9.2553 14.3587C8.42631 14.8561 7.74837 15.5698 7.29419 16.4232C7.1981 16.61 7.17932 16.827 7.24191 17.0275C7.3045 17.2281 7.44344 17.3959 7.62872 17.4948C7.81401 17.5938 8.03077 17.6159 8.23222 17.5564C8.43366 17.4969 8.60362 17.3605 8.70539 17.1768C9.02383 16.5797 9.49856 16.0803 10.0788 15.7322C10.6591 15.384 11.3231 15.2001 11.9998 15.2001C12.6765 15.2001 13.3405 15.384 13.9208 15.7322C14.501 16.0803 14.9758 16.5797 15.2942 17.1768C15.396 17.3605 15.5659 17.4969 15.7674 17.5564C15.9688 17.6159 16.1856 17.5938 16.3709 17.4948C16.5561 17.3959 16.6951 17.2281 16.7577 17.0275C16.8203 16.827 16.8015 16.61 16.7054 16.4232C16.2512 15.5698 15.5733 14.8561 14.7443 14.3587C13.9153 13.8614 12.9665 13.5991 11.9998 13.6Z"
        fill={color || '#6E6E6E'}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Sad;
