import theBasics from '../assets/the-basics.jpg';
import planningYourProgrammeDigitally from '../assets/planning-your-programme-digitally.jpg';
import planningEventsAndActivities from '../assets/planning-events-and-activities.jpg';
import finance from '../assets/finance.jpg';
import digitalMeetingsAndTraining from '../assets/digital-meetings-and-training.jpg';
import dataAndDocuments from '../assets/data-and-documents.jpg';
import workingTogether from '../assets/working-together.jpg';
import gettingTheWordOut from '../assets/getting-the-word-out.jpg';

import theBasics_small from '../assets/the-basics-small.jpg';
import planningYourProgrammeDigitally_small from '../assets/planning-your-programme-digitally-small.jpg';
import planningEventsAndActivities_small from '../assets/planning-events-and-activities-small.jpg';
import finance_small from '../assets/finance-small.jpg';
import digitalMeetingsAndTraining_small from '../assets/digital-meetings-and-training-small.jpg';
import dataAndDocuments_small from '../assets/data-and-documents-small.jpg';
import workingTogether_small from '../assets/working-together-small.jpg';
import gettingTheWordOut_small from '../assets/getting-the-word-out-small.jpg';

const images = {
  small: [
    theBasics_small,
    planningYourProgrammeDigitally_small,
    planningEventsAndActivities_small,
    finance_small,
    digitalMeetingsAndTraining_small,
    dataAndDocuments_small,
    workingTogether_small,
    gettingTheWordOut_small,
  ],
  large: [
    theBasics,
    planningYourProgrammeDigitally,
    planningEventsAndActivities,
    finance,
    digitalMeetingsAndTraining,
    dataAndDocuments,
    workingTogether,
    gettingTheWordOut,
  ],
};

const skillAreaHeaderImages = (skillAreaId = 0, isSmall) => {
  return isSmall ? images.small[skillAreaId] : images.large[skillAreaId];
};

export default skillAreaHeaderImages;
