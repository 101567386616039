import React from 'react';
import * as S from './style';
import Icon from '../../Icon';
import * as T from '../../Typography';
import { navRoutes, roles } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { format } from 'date-fns';

const handleState = ({
  state,
  date,
  showDateOnly,
  showStateOnly,
  shortDate,
}) => {
  if (!date) {
    return '';
  }
  try {
    if (showDateOnly) {
      return shortDate ? format(new Date(date), 'dd/MM/yy') : date;
    }
    if (showStateOnly) {
      return state;
    }
    return state && date
      ? `${state} ${shortDate ? format(new Date(date), 'dd/MM/yy') : date}`
      : state || date;
  } catch (e) {
    console.error('error with date', date);
    return date || '';
  }
};

const QuestionCard = ({
  state,
  date,
  title,
  id,
  showDateOnly,
  showStateOnly,
  skillArea,
  shortDate,
  ...props
}) => {
  const history = useHistory();
  const { user } = useAuth();
  return (
    <S.Container
      onClick={() =>
        user?.role === roles.HQ
          ? history.push(navRoutes.HQ.QUESTION_DETAILS.replace(':id', id))
          : history.push(
              navRoutes.DIGITAL_CHAMPION.QUESTION_DETAILS.replace(':id', id)
            )
      }
      {...props}
    >
      <S.Content>
        <T.BodyB color="gray">{title}</T.BodyB>
        <S.StatusWrapper>
          <T.Body16B color="blue">
            {handleState({
              date,
              state,
              showDateOnly,
              showStateOnly,
              shortDate,
            })}
          </T.Body16B>
          {skillArea && (
            <>
              <T.Body16B color="gray" ml="1" mr="1">
                |
              </T.Body16B>
              <T.Body16B color="green">{skillArea}</T.Body16B>
            </>
          )}
        </S.StatusWrapper>
      </S.Content>
      <S.IconWrapper>
        <Icon icon="rightRow" width="18" height="18" color="gray" />
      </S.IconWrapper>
    </S.Container>
  );
};

export default QuestionCard;
