const Quote = ({ width, height, color, ...props }) => (
  <svg
    width={width || '29'}
    height={height || '55'}
    viewBox="0 0 29 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.8 54.8V36.6c0-6.8 1.2-13.067 3.6-18.8C6.8 12.067 10.868 6.267 16.6.4L27 8.6c-3.332 3.333-5.866 6.467-7.6 9.4-1.732 2.933-2.866 5.933-3.4 9h12.8v27.8H.8Z"
      fill={color || '#666666'}
    />
  </svg>
);

export default Quote;
