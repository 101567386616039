import { fields, createSchema, validate as _validate } from '..';

const { optionalFiled, booleanFiled } = fields;

const schema = createSchema({
  suggestedSkills: optionalFiled,
  givePermission: booleanFiled,
});

const validate = (data) => {
  return _validate(schema, data);
};

export default validate;
