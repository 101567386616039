import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
  filter: ${({ theme }) => theme.shadows.card};
`;

export const ImgWrapper = styled.div`
  width: 100%;
  background-image: ${({ image }) => `url(${image})`};
  min-height: 180px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
`;

export const IconWrapper = styled.div`
  width: 100%;
  min-height: 180px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, bg }) => theme.colors[bg]};
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InnerWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const GrayDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 107px;
  background: ${({ theme }) => theme.colors.litestGray};
  border: ${({ theme }) => `1px solid ${theme.colors.literGray}`};
  padding: 8px 0px;
`;

export const FooterWrapper = styled.div`
  background: ${({ theme }) => theme.colors.blue};
  padding: 8px;
`;
