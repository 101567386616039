import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  padding: ${({ theme }) => theme.spacings[3]};
  background-color: ${({ theme }) => theme.colors.litestGray};
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
`;

export const ListItem = styled.a`
  ${setMargin};
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  outline: none;
`;

export const InnerWrapper = styled.div`
  ${setMargin};
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  outline: none;
  margin-left: ${({ theme }) => theme.spacings[3]};
`;
