import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin-bottom: ${({ mb, theme }) => theme.spacings[mb] || '0'};
  background: ${({ theme, bgColor }) =>
    (bgColor && theme.colors[bgColor]) || bgColor};
  padding-bottom: 9px;
  padding-top: 9px;
  padding-left: ${({ inBar }) => (inBar ? 'min(8.3vw, 120px)' : 0)};
  width: 100%;
  ${({ theme }) => theme.media.mobile} {
    padding-left: ${({ inBar }) => (inBar ? 'min(4vw, 8px)' : 0)};
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  :focus-visible {
    outline: ${({ theme, color }) => `2px ${theme.colors[color]} solid`};
  }
  cursor: pointer;
  align-items: center;
  display: flex;
`;
