import axios from 'axios';
import handleError from './format-error';
import { handleValidationError } from '../validation/validate';
import { dataTypes as dt } from '../constants';

const QUESTION_BASE = '/questions';

const getQuestionsForDC = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}`, {
      params: {
        lastThree: true,
        showHidden: false,
        type: dt.questionTypes.UNRESOLVED,
      },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getAllUnresolvedQuestions = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}`, {
      params: { showHidden: true, type: dt.questionTypes.UNRESOLVED },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSolvedQuestions = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}`, {
      params: { type: dt.questionTypes.RESOLVED },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const getDCResponses = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}`, {
      params: { lastThree: true, type: dt.questionTypes.ASSIGNED },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getQuestionById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}/${id}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const hideQuestionById = async ({ id, isHidden, options }) => {
  try {
    const { data } = await axios.put(`${QUESTION_BASE}/hide`, {
      id,
      isHidden,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getMostPopularQuestions = async ({
  limit = 3,
  isResolved,
  options,
} = {}) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}/popular`, {
      params: { limit, isResolved },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const answerQuestionById = async ({
  id,
  title,
  skills,
  answer,
  files,
  options,
}) => {
  try {
    const { data } = await axios.put(`${QUESTION_BASE}/answer/${id}`, {
      title,
      skills,
      answer,
      files,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const SearchQuestions = async ({ question, isResolved, options }) => {
  try {
    const { data } = await axios.get(`${QUESTION_BASE}/search`, {
      params: { searchText: question, isResolved },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const sendQuestion = async ({ options, form } = {}) => {
  try {
    const { data } = await axios.post(`${QUESTION_BASE}`, form);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(error.response.data.data);

      return { error: err, inner };
    }
    return { error: err };
  }
};

const sendReplyToAnsweredQuestion = async ({
  options,
  questionId,
  answerId,
  userAskedEmail,
  reply,
} = {}) => {
  try {
    const { data } = await axios.post(
      `${QUESTION_BASE}/answer/${answerId}/replies`,
      {
        questionId,
        reply,
        userAskedEmail,
      }
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(error.response.data.data);

      return { error: err, inner };
    }
    return { error: err };
  }
};

const markQuestionAsResolved = async ({
  options,
  questionId,
  userAskedEmail,
} = {}) => {
  try {
    const { data } = await axios.patch(
      `${QUESTION_BASE}/${questionId}/mark-resolved`,
      {
        userAskedEmail,
      }
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const handleHandOver = async ({
  options,
  questionId,
  comment,
  answerId,
} = {}) => {
  try {
    const { data } = await axios.put(
      `${QUESTION_BASE}/${questionId}/handover`,
      {
        comment,
        answerId,
      }
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(error.response.data.data);

      return { error: err, inner };
    }
    return { error: err };
  }
};

const deleteQuestion = async ({ options, questionId } = {}) => {
  try {
    const { data } = await axios.delete(`${QUESTION_BASE}/${questionId}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteAnswer = async ({ options, answerId } = {}) => {
  try {
    const { data } = await axios.delete(`${QUESTION_BASE}/answer/${answerId}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const deleteReply = async ({ options, replyId } = {}) => {
  try {
    const { data } = await axios.delete(`${QUESTION_BASE}/reply/${replyId}`);

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const getAnswerReplies = async ({ answerId, limit = 20, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${QUESTION_BASE}/answer/${answerId}/replies`,
      {
        params: { limit },
      }
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const notifyEmail = async ({ options, questionId, email } = {}) => {
  try {
    const { data } = await axios.put(`${QUESTION_BASE}/${questionId}/follow`, {
      email,
      follow: true,
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getQuestionsForDC,
  getDCResponses,
  getAllUnresolvedQuestions,
  getSolvedQuestions,
  getMostPopularQuestions,
  SearchQuestions,
  sendQuestion,
  getQuestionById,
  hideQuestionById,
  answerQuestionById,
  sendReplyToAnsweredQuestion,
  markQuestionAsResolved,
  handleHandOver,
  deleteQuestion,
  deleteAnswer,
  deleteReply,
  getAnswerReplies,
  notifyEmail,
};
