const Heart = ({ width, height, color, ...props }) => (
  <svg
    width={width || '21'}
    height={height || '18'}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.81802 2.31802C1.06066 4.07538 1.06066 6.92462 2.81802 8.68198L10.5001 16.364L18.182 8.68198C19.9393 6.92462 19.9393 4.07538 18.182 2.31802C16.4246 0.56066 13.5754 0.56066 11.818 2.31802L10.5001 3.63609L9.18198 2.31802C7.42462 0.56066 4.57538 0.56066 2.81802 2.31802Z"
      stroke="#0061CE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Heart;
