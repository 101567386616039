import { fields, createSchema, validate as _validate } from '..';

const requestAChampion = createSchema({
  name: fields.requestChampionNameIfLoggedIn,
  email: fields.requestChampionEmailIfLoggedIn,
  phoneNumber: fields.optionalPhoneNumber,
  contactPreference: fields.requiredText,
  skillAreas: fields.arrayOfStrings,
  otherSkillAreas: fields.otherSkillAreas,
  district: fields.requiredText,
  additionalInfo: fields.textArea140w,
});

const validate = (data) => {
  return _validate(requestAChampion, data);
};

export default validate;
