const TrophyStar = ({ width, height, color, strokeColor, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={width || '48'}
    height={height || '48'}
    {...props}
  >
    <path
      d="M90.56 50.69l-11.23-1.63-5-10.18a1.74 1.74 0 00-1.57-1 1.77 1.77 0 00-1.58 1l-5.43 11a1.762 1.762 0 001.44 2.62 1.76 1.76 0 001.71-1.06l3.86-7.81 3.85 7.81a1.75 1.75 0 001.33 1l8.62 1.25-6.24 6.09a1.72 1.72 0 00-.51 1.55l1.48 8.59-7.72-4.06a1.74 1.74 0 00-1.63 0l-7.74 4.02 1.47-8.59a1.72 1.72 0 00-.51-1.55l-6.23-6.09 1.59-.23a1.76 1.76 0 00-.52-3.48l-5.11.75a1.73 1.73 0 00-1.42 1.19 1.76 1.76 0 00.44 1.81l8.13 7.92-1.91 11.18a1.77 1.77 0 001.605 2.055 1.75 1.75 0 00.945-.195l10.05-5.28 10 5.28c.254.13.535.198.82.2a1.7 1.7 0 001-.34 1.75 1.75 0 00.7-1.72L83.4 61.61l8.13-7.92a1.79 1.79 0 00.47-1.81 1.759 1.759 0 00-1.44-1.19z"
      fill={color || '#fff'}
    />
    <path
      d="M125.93 19.47h-15.7a1.757 1.757 0 00-1.626 1.086 1.768 1.768 0 00-.134.674 7.632 7.632 0 01-5.86 7.41v-7.41a1.75 1.75 0 00-.515-1.245 1.772 1.772 0 00-1.245-.515H44.6a1.761 1.761 0 00-1.76 1.76v7.41A7.63 7.63 0 0137 21.23a1.747 1.747 0 00-1.75-1.76H19.52a1.75 1.75 0 00-1.75 1.76 54.92 54.92 0 007.12 27.07 55.23 55.23 0 0018 19v7.08A29.93 29.93 0 0065 103.29l-2 11.57h-2a15.84 15.84 0 00-15.74 14.23 8.812 8.812 0 00-6.93 6.91h-.76a1.78 1.78 0 00-.713.093 1.772 1.772 0 00-1.033.958 1.761 1.761 0 001.746 2.459h70.31a1.762 1.762 0 001.746-2.459A1.763 1.763 0 00107.88 136h-.76a8.809 8.809 0 00-6.93-6.87 15.847 15.847 0 00-15.74-14.23h-2l-1.93-11.57a29.918 29.918 0 0022.12-28.86v-7.12a55.348 55.348 0 0018-19 55 55 0 007.12-27.07 1.76 1.76 0 00-1.83-1.81zM42.84 63.11A51.15 51.15 0 0121.31 23h12.3a11.151 11.151 0 009.23 9.23v30.88zm38.08 55.27h3.53a12.31 12.31 0 0112.17 10.55H61a1.78 1.78 0 00-.713.093 1.772 1.772 0 00-1.033.958A1.761 1.761 0 0061 132.44h37.5a5.278 5.278 0 015 3.52H42a5.294 5.294 0 015-3.52h7a1.76 1.76 0 000-3.51h-5.17A12.321 12.321 0 0161 118.38h19.92zm-14.32-3.52L68.41 104c2.848.423 5.742.423 8.59 0l1.81 10.86H66.6zm32.49-40.43a26.37 26.37 0 11-52.73 0V23h52.73v51.43zm3.52-11.32V32.22a11.172 11.172 0 009.24-9.22h12.29a51.148 51.148 0 01-21.53 40.11z"
      fill={color || '#fff'}
    />
  </svg>
);

export default TrophyStar;
