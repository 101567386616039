import * as S from './style';
import * as T from '../Typography';

const Tag = ({ text, to, bgColor, color, transparent = true, type }) => {
  if (to)
    return (
      <S.LinkWrapper
        bgColor={bgColor}
        transparent={transparent}
        to={to}
        type={type}
      >
        <T.Body16B color={color}>{text}</T.Body16B>
      </S.LinkWrapper>
    );

  return (
    <S.TagWrapper bgColor={bgColor} transparent={transparent} type={type}>
      <T.Body16B color={color}>{text}</T.Body16B>
    </S.TagWrapper>
  );
};

export default Tag;
