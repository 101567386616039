const CircleStar = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={width || '150'}
    height={height || '150'}
    {...props}
  >
    <path
      d="M117.43 32.577A60.001 60.001 0 10135 75.007a59.614 59.614 0 00-17.57-42.43zm-4 80.6a54.086 54.086 0 01-68.3 6.731 54.093 54.093 0 01-19.922-65.675A54.09 54.09 0 01129.27 74.937a53.721 53.721 0 01-15.82 38.24h-.02z"
      fill={color || 'white'}
    />
    <path
      d="M76.07 48.647l7.8 15.88a1.211 1.211 0 00.9.66l17.521 2.54a1.202 1.202 0 01.955 1.438c-.046.21-.148.405-.295.562l-12.64 12.39a1.188 1.188 0 00-.34 1.06l3 17.45a1.2 1.2 0 01-1.73 1.26l-15.68-8.24a1.21 1.21 0 00-1.11 0l-15.67 8.24a1.191 1.191 0 01-1.653-.594 1.192 1.192 0 01-.077-.646l3-17.45a1.19 1.19 0 00-.34-1.06l-12.69-12.37a1.2 1.2 0 01.66-2l17.52-2.54a1.23 1.23 0 00.9-.66l7.83-15.92a1.19 1.19 0 012.14 0z"
      fill={color || 'white'}
    />
  </svg>
);

export default CircleStar;
