import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as T from '../Typography';
import { Checkbox, BasicInput as Input, Textarea, Dropdown } from '../Inputs';
import Button from '../Button';
import validate from '../../validation/schemas/update-skill';
import { Skills } from '../../api-calls';
import Icon from '../Icon';
import { Popover, Typography } from 'antd';
import { navRoutes } from '../../constants';
import SelectIcon from '../SelectIcon';

const { Paragraph } = Typography;

const SkillEdit = ({
  id,
  title,
  description,
  keywords,
  icon,
  tasks = [],
  hideSkill,
  skillAreas,
  onUpdate,
  skillAreaOptions,
}) => {
  const history = useHistory();
  const [state, setState] = useState({
    id,
    hideSkill: hideSkill || false,
    skillAreas: skillAreas || [],
    title: title || '',
    description: description || '',
    icon: icon || '',
    task1: tasks?.[0] || '',
    task2: tasks?.[1] || '',
    task3: tasks?.[2] || '',
    keywords: keywords || [],
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const handleDropdown = (value) => {
    // filter out the skillAreas whose id is not in the value array
    const filteredSkillAreas = skillAreaOptions.filter((skillArea) =>
      value.includes(skillArea.value)
    );
    handleChange('skillAreas', filteredSkillAreas);
  };

  const handleChange = (name, value) => {
    setState((_state) => ({
      ..._state,
      [name]: value,
    }));
  };

  const editSkill = async () => {
    setLoading(true);
    let res;
    if (id) {
      res = await Skills.editSkill({
        id,
        form: {
          title: state.title,
          description: state.description,
          icon: state.icon,
          hideSkill: state.hideSkill,
          tasks: [state.task1, state.task2, state.task3],
          skillAreas: state.skillAreas.map((skillArea) => skillArea.id),
          keywords: state.keywords,
        },
      });
    } else {
      res = await Skills.createSkill({
        id,
        form: {
          title: state.title,
          description: state.description,
          icon: state.icon,
          hideSkill: state.hideSkill,
          tasks: [state.task1, state.task2, state.task3],
          skillAreas: state.skillAreas.map((skillArea) => skillArea.id),
          keywords: state.keywords,
        },
      });
    }
    const { error, data } = res;

    setLoading(false);
    if (error) {
      setErrors({ http: error.data?.message || error.message });
      throw error;
    } else {
      onUpdate(state);
    }

    return data?.id;
  };

  const handleSubmit = async () => {
    try {
      validate(state);
      setErrors({});
      const id = await editSkill();
      console.log('id', id);
      history.push(navRoutes.HQ.INDIVIDUAL_SKILL.replace(':id', id));
      return true;
    } catch (error) {
      console.log('error', error);
      // if (error.name === 'ValidationError') {
      //   setErrors({ ...error.inner });
      // }
      return false;
    }
  };

  const promptText = `Generate keywords for this skill to improve search engine optimisation:
  skill title: "${state.title}"
  skill description: "${state.description}"
  ${state.task1 ? `task 1: "${state.task1}"` : ''}
  ${state.task2 ? `task 2: "${state.task2}"` : ''}
  ${state.task3 ? `task 3: "${state.task3}"` : ''}
  
  keywords should be separated by commas eg. "keyword1, keyword2, keyword3"`;

  return (
    <>
      {id && (
        <Checkbox
          label="Hide this skill from my volunteers"
          error={null}
          checked={state.hideSkill}
          handleChange={(checked) => handleChange('hideSkill', checked)}
        />
      )}
      {/* Title */}
      <Input
        label="Skill title"
        name="title"
        value={state.title}
        handleChange={(e) => handleChange('title', e.target.value)}
        placeholder="New skill I created"
        error={errors.title}
        disabled={state.hideSkill}
      />
      <Dropdown
        placeholder="Select skill area"
        selected={state.skillAreas}
        multi
        handleChange={handleDropdown}
        options={skillAreaOptions}
        disabled={state.hideSkill}
      />

      {/* Description */}
      <Textarea
        type="textarea"
        name="description"
        rows="3"
        cols="33"
        value={state.description}
        handleChange={(e) => handleChange('description', e.target.value)}
        label="How can this skill help in the real world?"
        placeholder="description..."
        error={errors.description}
        disabled={state.hideSkill}
      />

      {/* icon */}
      {/* <Input
        name="icon"
        value={state.icon}
        handleChange={(e) => handleChange('icon', e.target.value)}
        label="Icon"
        placeholder="icon..."
        error={errors.icon}
        disabled={state.hideSkill}
      /> */}

      <SelectIcon
        icon={state.icon}
        setIcon={(icon) => handleChange('icon', icon)}
        error={errors.icon}
        disabled={state.hideSkill}
      />

      {/* optional tasks */}
      <Input
        label="Volunteering tasks this skill can help with"
        helper="(optional)"
        placeholder="task 1"
        name="task1"
        value={state.task1}
        handleChange={(e) => handleChange('task1', e.target.value)}
        error={errors.task1}
        disabled={state.hideSkill}
      />
      <Input
        placeholder="task 2"
        name="task2"
        value={state.task2}
        handleChange={(e) => handleChange('task2', e.target.value)}
        disabled={!state.task1 || state.hideSkill}
        error={errors.task2}
      />
      <Input
        placeholder="task 3"
        name="task3"
        value={state.task3}
        handleChange={(e) => handleChange('task3', e.target.value)}
        disabled={!state.task2 || state.hideSkill}
        error={errors.task3}
        mb="3"
      />

      <Dropdown
        placeholder="Keywords"
        label="Keywords"
        selected={state.keywords}
        helper={
          <Popover
            placement="topLeft"
            trigger={'click'}
            cursor="pointer"
            content={
              <>
                <T.Body16 color="gray" m="0" mb="1" ml="1">
                  - Input each keyword and hit "Enter"
                </T.Body16>
                <T.Body16 color="gray" m="0" mb="1" ml="1">
                  - Alternatively, you can paste a comma-separated list of
                  keywords such as "keyword1, keyword2, keyword3"
                </T.Body16>
                <T.Body16 color="gray" m="0" mb="1" ml="1">
                  - Employ this prompt with ChatGPT to produce keywords.
                  <Paragraph
                    style={{
                      display: 'inline',
                    }}
                    copyable={{
                      text: promptText,
                    }}
                  ></Paragraph>
                </T.Body16>
                <T.Body16 color="gray" m="0" mb="1" ml="1">
                  - Acquire the outcomes from ChatGPT and place them in this
                  space.
                </T.Body16>
              </>
            }
          >
            <Icon icon="info" width={20} height={20} cursor="pointer" />
          </Popover>
        }
        additionalHelper={'Keywords help volunteers find your skill'}
        multi
        mode="tags"
        handleChange={(values) => setState({ ...state, keywords: values })}
        disabled={state.hideSkill}
      />

      <T.Body16B color="red">{errors.http}</T.Body16B>
      {/* submit */}
      <Button handleClick={handleSubmit} loading={loading}>
        Submit
      </Button>
    </>
  );
};

export default SkillEdit;
