const ActivityComplete = ({ width, height, color, ...props }) => (
  <svg
    width={width || '150'}
    height={height || '150'}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M117.43 32.5773C107.615 22.7609 94.7006 16.6514 80.8864 15.2895C67.0721 13.9277 53.213 17.3979 41.6705 25.1088C30.1279 32.8197 21.6161 44.2943 17.5853 57.5774C13.5545 70.8605 14.2541 85.1303 19.5649 97.9554C24.8757 110.781 34.4691 121.367 46.7106 127.912C58.952 134.457 73.0841 136.555 86.6989 133.849C100.314 131.142 112.569 123.798 121.376 113.069C130.184 102.34 134.998 88.8885 135 75.0073C135.022 67.1245 133.481 59.3155 130.465 52.0324C127.449 44.7493 123.019 38.1366 117.43 32.5773ZM113.43 113.177C104.582 122.027 92.9384 127.535 80.4844 128.762C68.0303 129.989 55.536 126.86 45.1305 119.908C34.725 112.955 27.0521 102.61 23.4193 90.6344C19.7864 78.659 20.4184 65.7943 25.2076 54.2326C29.9968 42.6709 38.6467 33.1275 49.6836 27.2286C60.7205 21.3297 73.4613 19.4403 85.7351 21.8823C98.0089 24.3244 109.056 30.9467 116.995 40.621C124.933 50.2953 129.271 62.4229 129.27 74.9373C129.292 82.0406 127.905 89.0777 125.189 95.6414C122.474 102.205 118.484 108.165 113.45 113.177H113.43Z"
      fill={color || 'white'}
    />
    <path
      d="M76.0703 48.6473L83.8703 64.5273C83.9564 64.7014 84.0831 64.8523 84.2397 64.9672C84.3964 65.0821 84.5784 65.1576 84.7704 65.1873L102.29 67.7273C102.502 67.7661 102.7 67.8613 102.862 68.0028C103.025 68.1443 103.146 68.327 103.213 68.5315C103.281 68.7361 103.292 68.9551 103.246 69.1655C103.2 69.3759 103.098 69.5699 102.95 69.7273L90.3104 82.1173C90.1716 82.2528 90.068 82.4203 90.0088 82.6051C89.9495 82.7898 89.9363 82.9862 89.9703 83.1773L92.9703 100.627C93.0056 100.846 92.9796 101.07 92.8952 101.275C92.8108 101.48 92.6713 101.658 92.4921 101.788C92.313 101.919 92.1012 101.997 91.8802 102.015C91.6593 102.032 91.4378 101.988 91.2404 101.887L75.5604 93.6473C75.3888 93.5587 75.1985 93.5125 75.0054 93.5125C74.8122 93.5125 74.6219 93.5587 74.4503 93.6473L58.7803 101.887C58.5849 101.99 58.3646 102.037 58.1442 102.022C57.9237 102.007 57.7118 101.93 57.5323 101.802C57.3527 101.673 57.2125 101.497 57.1274 101.293C57.0422 101.089 57.0156 100.865 57.0503 100.647L60.0503 83.1973C60.0844 83.0062 60.0712 82.8098 60.0119 82.625C59.9527 82.4403 59.8491 82.2728 59.7103 82.1373L47.0203 69.7673C46.8732 69.6099 46.7711 69.4159 46.7248 69.2055C46.6785 68.9951 46.6897 68.7761 46.7572 68.5715C46.8247 68.367 46.9461 68.1843 47.1085 68.0428C47.2709 67.9013 47.4685 67.8061 47.6803 67.7673L65.2003 65.2273C65.3918 65.196 65.5731 65.1199 65.7295 65.0052C65.8859 64.8905 66.013 64.7404 66.1004 64.5673L73.9303 48.6473C74.028 48.4466 74.1801 48.2775 74.3693 48.1591C74.5585 48.0408 74.7772 47.978 75.0003 47.978C75.2235 47.978 75.4422 48.0408 75.6314 48.1591C75.8206 48.2775 75.9727 48.4466 76.0703 48.6473Z"
      fill={color || 'white'}
    />
  </svg>
);

export default ActivityComplete;
