import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Icon from '../../Icon';
import * as T from '../../Typography';
import * as S from './style';
import theme from '../../../theme';
import { GENERAL } from '../../../constants/nav-routes';
import Tag from '../../Tag';
import { Skills } from '../../../api-calls';
import Prompt from '../../../pages/VolunteerIndividualSkill/Prompt';

const truncate150 = (str) => {
  if (str.length > 150) {
    return `${str.slice(0, 150)}...`;
  }
  return str;
};

const RecommendSkill = ({
  title,
  description,
  id,
  totalActivities,
  bookmarked: _bookmarked,
  userId,
}) => {
  const [loading, setLoading] = useState(false);
  const [, setHttpError] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [bookmarked, setBookedmarked] = useState(_bookmarked);

  const updateBookmarkSkill = async (cb) => {
    setLoading(true);
    try {
      await Skills.bookmarkSkill({ skillId: id, bookmark: !bookmarked });
      setBookedmarked(!bookmarked);
    } catch (error) {
      setHttpError(error.message);
    }
    setLoading(false);
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });

  const handleBookmarkClick = async () => {
    if (!bookmarked) {
      setPrompt(true);
    } else {
      await updateBookmarkSkill();
    }
  };

  return (
    <>
      <S.Container isMobile={isMobile}>
        <S.BookmarkedWrapper>
          <Icon
            onClick={handleBookmarkClick}
            icon="bookmark"
            stroke="black"
            color={bookmarked ? 'black' : 'white'}
            width="15"
            height="50"
          />
        </S.BookmarkedWrapper>
        <S.ContentWrapper to={GENERAL.SKILL.replace(':id', id)}>
          <T.BodyB mt="3" ml="3" mr="6" style={{ fontWeight: 900 }}>
            {title}
          </T.BodyB>
          {!isMobile && description && (
            <T.Body16B mt="3" ml="3" mr="6" style={{ fontWeight: 400 }}>
              {truncate150(description)}
            </T.Body16B>
          )}
          <S.Content>
            <T.Body16B>{totalActivities} activities</T.Body16B>
            <Tag text="Beginner" bgColor="navy" color="white" />
          </S.Content>
        </S.ContentWrapper>
      </S.Container>
      {prompt && (
        <Prompt
          open={prompt}
          setOpen={setPrompt}
          updateBookmarkSkill={updateBookmarkSkill}
          loading={loading}
          userId={userId}
          bookmarked={bookmarked}
        />
      )}
    </>
  );
};

export default RecommendSkill;
