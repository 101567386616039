import React, { useEffect, useState } from 'react';
import * as S from './style';
import * as T from '../../Typography';
import { skillAreaHeaderImages } from '../../../helpers';
import Tag from '../../Tag';
import Icon from '../../Icon';

const SkillAreaCard = ({
  id,
  image,
  title,
  skillsNumber,
  activitiesNumber,
  description,
  linkUrl,
  icon,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(skillAreaHeaderImages(image - 1, true));

  useEffect(() => {
    const img = new Image();
    const bigImg = skillAreaHeaderImages(image - 1);
    img.src = bigImg;
    img.onload = () => {
      setImgSrc(bigImg);
    };
  }, [image]);

  const colours = ['blue', 'teal', 'navy'];
  const bgColor = colours[(id - 1) % colours.length];

  return (
    <S.Wrapper {...props} to={linkUrl}>
      {image && <S.ImgWrapper image={imgSrc} />}
      {icon && (
        <S.IconWrapper bg={bgColor}>
          <Icon width={100} icon={icon} color="white" />
        </S.IconWrapper>
      )}
      <S.InnerWrapper>
        <S.ContentWrapper>
          <T.H3 override="h6" mt="3" mb="2">
            {title}
          </T.H3>
          {description && (
            <T.BodySM
              mt="2"
              mb="2"
              style={{
                flex: 1,
              }}
            >
              {description}
            </T.BodySM>
          )}
          <S.TagWrapper>
            <Tag text={`${skillsNumber} skills`} bgColor="blue" color="white" />
            <Tag text="Beginner" bgColor="navy" color="white" />
          </S.TagWrapper>
        </S.ContentWrapper>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default SkillAreaCard;
