import React, { useRef, useEffect } from 'react';
import * as S from './style';
import * as T from '../../Typography';

import { format } from 'date-fns';

const ReplyCard = ({ name, content, replyDate, ...props }) => {
  const firstName = name.split(' ')[0];

  const htmlRef = useRef();

  useEffect(() => {
    if (htmlRef.current && htmlRef.current.querySelectorAll('a')) {
      const list = htmlRef.current.querySelectorAll('a');
      list.forEach((item) => {
        if (!item.getAttribute('href').includes('//')) {
          item.setAttribute('href', `//${item.getAttribute('href')}`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlRef.current]);

  return (
    <S.Wrapper {...props} column>
      <S.NoteHeader>
        <S.NoteTexts>
          <T.Body16B color={'blue'}>{firstName}</T.Body16B>

          <T.BodySM color="gray">
            Replied {format(new Date(replyDate), 'dd MMM, yyyy')}
          </T.BodySM>
        </S.NoteTexts>
      </S.NoteHeader>
      <S.NoteTexts>
        <S.RichText
          ref={htmlRef}
          mt={2}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </S.NoteTexts>
    </S.Wrapper>
  );
};

export default ReplyCard;
