const Bell = ({ width, height, color, ...props }) => (
  <svg
    width={width || '28'}
    height={height || '28'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 9.33337C21 7.47686 20.2625 5.69638 18.9497 4.38363C17.637 3.07087 15.8565 2.33337 14 2.33337C12.1435 2.33337 10.363 3.07087 9.05025 4.38363C7.7375 5.69638 7 7.47686 7 9.33337C7 17.5 3.5 19.8334 3.5 19.8334H24.5C24.5 19.8334 21 17.5 21 9.33337Z"
      stroke={color || '#0061CE'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0183 24.5C15.8132 24.8536 15.5188 25.1471 15.1646 25.3511C14.8104 25.5551 14.4088 25.6625 14 25.6625C13.5912 25.6625 13.1896 25.5551 12.8354 25.3511C12.4812 25.1471 12.1868 24.8536 11.9817 24.5"
      stroke={color || '#0061CE'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bell;
