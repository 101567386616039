const Calculator = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120 120"
    width={width || '150'}
    height={height || '150'}
    {...props}
  >
    <path
      d="M112.5 55H110V7.5a7.498 7.498 0 00-7.5-7.5h-95A7.5 7.5 0 000 7.5v65A7.5 7.5 0 007.5 80h25v20H30a2.5 2.5 0 100 5h35v7.5a7.499 7.499 0 007.5 7.5h40a7.497 7.497 0 007.5-7.5v-50a7.498 7.498 0 00-7.5-7.5zM5 7.5A2.5 2.5 0 017.5 5h95a2.5 2.5 0 012.5 2.5V55H72.5a7.5 7.5 0 00-7.05 5H5V7.5zm0 65V65h60v10H7.5A2.5 2.5 0 015 72.5zM37.5 100V80H65v20H37.5zm77.5 12.5a2.5 2.5 0 01-2.5 2.5h-40a2.5 2.5 0 01-2.5-2.5v-50a2.5 2.5 0 012.5-2.5h40a2.501 2.501 0 012.5 2.5v50z"
      fill={color || '#6E6E6E'}
    />
    <path
      d="M107.5 65h-30a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h30a2.501 2.501 0 002.5-2.5v-10a2.5 2.5 0 00-2.5-2.5zM105 75H80v-5h25v5zM82.5 85h-5a2.5 2.5 0 000 5h5a2.5 2.5 0 000-5zM95 85h-5a2.5 2.5 0 000 5h5a2.5 2.5 0 100-5zM107.5 85h-5a2.501 2.501 0 000 5h5a2.501 2.501 0 000-5zM82.5 95h-5a2.5 2.5 0 000 5h5a2.5 2.5 0 000-5zM95 95h-5a2.5 2.5 0 000 5h5a2.5 2.5 0 100-5zM82.5 105h-5a2.5 2.5 0 100 5h5a2.5 2.5 0 100-5zM95 105h-5a2.499 2.499 0 100 5h5a2.499 2.499 0 100-5zM107.5 95h-5a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h5a2.5 2.5 0 002.5-2.5v-10a2.5 2.5 0 00-2.5-2.5zM57.5 45v-.45A7.5 7.5 0 0055 30a2.5 2.5 0 112.5-2.5 2.5 2.5 0 005 0 7.5 7.5 0 00-5-7.05V20a2.5 2.5 0 00-5 0v.45A7.5 7.5 0 0055 35a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 00-5 0 7.5 7.5 0 005 7.05V45a2.5 2.5 0 005 0z"
      fill={color || '#6E6E6E'}
    />
    <path
      d="M77.5 32.5a22.5 22.5 0 10-44.999 0 22.5 22.5 0 0045 0zm-40 0a17.5 17.5 0 1135 0 17.5 17.5 0 01-35 0zM55 72.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      fill={color || '#6E6E6E'}
    />
  </svg>
);

export default Calculator;
