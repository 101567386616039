const ScoutStack = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 110 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>1B137F56-93C7-4404-A869-11B6B124FB3F</title>
    <desc>Created with sketchtool.</desc>
    <defs></defs>
    <g
      id="symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Footer"
        transform="translate(-665.000000, -48.000000)"
        fill={color || '#ffffff'}
      >
        <g
          id="Scouts_Logo_Stack_White"
          transform="translate(665.000000, 48.000000)"
        >
          <path
            d="M0,76.0275519 L3.18433333,72.2293776 C5.38866667,74.0414938 7.69833333,75.1913693 10.497,75.1913693 C12.701,75.1913693 14.0313333,74.32 14.0313333,72.8916183 L14.0313333,72.8215768 C14.0313333,71.4625726 13.191,70.7654772 9.097,69.7204979 C4.16333333,68.4660581 0.979,67.1070539 0.979,62.2632365 L0.979,62.1938589 C0.979,57.7682988 4.54833333,54.8411618 9.55233333,54.8411618 C13.121,54.8411618 16.1653333,55.9561826 18.6503333,57.9429046 L15.8506667,61.9850622 C13.6813333,60.486639 11.5466667,59.5804149 9.48166667,59.5804149 C7.41833333,59.5804149 6.333,60.5211618 6.333,61.7058921 L6.333,61.7756017 C6.333,63.3792531 7.38233333,63.9014108 11.617,64.9812448 C16.5853333,66.2705394 19.384,68.0478008 19.384,72.2990871 L19.384,72.3687967 C19.384,77.2122822 15.6756667,79.9299585 10.392,79.9299585 C6.683,79.9299585 2.939,78.6409959 0,76.0275519"
            id="Fill-1"
          ></path>
          <path
            d="M21.3946667,70.3478838 L21.3946667,70.2778423 C21.3946667,64.94639 25.4883333,60.5560166 31.2266667,60.5560166 C34.7623333,60.5560166 36.966,61.7407469 38.716,63.6919502 L35.4616667,67.1767635 C34.272,65.9226556 33.0826667,65.1209959 31.1926667,65.1209959 C28.5326667,65.1209959 26.6433333,67.4556017 26.6433333,70.2081328 L26.6433333,70.2778423 C26.6433333,73.1352697 28.4973333,75.4350207 31.4016667,75.4350207 C33.1873333,75.4350207 34.412,74.6685477 35.7063333,73.4489627 L38.8203333,76.5855602 C37.0013333,78.5712863 34.9016667,80 31.157,80 C25.5233333,80 21.3946667,75.6793361 21.3946667,70.3478838"
            id="Fill-3"
          ></path>
          <path
            d="M39.862,70.3478838 L39.862,70.2778423 C39.862,64.9118672 44.2013333,60.5560166 50.0443333,60.5560166 C55.8533333,60.5560166 60.156,64.8424896 60.156,70.2081328 L60.156,70.2778423 C60.156,75.6441494 55.8176667,80 49.9746667,80 C44.1653333,80 39.862,75.7145228 39.862,70.3478838 M54.9083333,70.3478838 L54.9083333,70.2778423 C54.9083333,67.5249793 52.914,65.1209959 49.9746667,65.1209959 C46.9303333,65.1209959 45.1106667,67.4556017 45.1106667,70.2081328 L45.1106667,70.2778423 C45.1106667,73.0310373 47.1046667,75.4350207 50.0443333,75.4350207 C53.0886667,75.4350207 54.9083333,73.1004149 54.9083333,70.3478838"
            id="Fill-4"
          ></path>
          <path
            d="M62.2316667,72.9958506 L62.2316667,60.9042324 L67.5506667,60.9042324 L67.5506667,71.3234855 C67.5506667,73.8323651 68.74,75.1216598 70.7693333,75.1216598 C72.7986667,75.1216598 74.0936667,73.8323651 74.0936667,71.3234855 L74.0936667,60.9042324 L79.4126667,60.9042324 L79.4126667,79.5817427 L74.0936667,79.5817427 L74.0936667,76.9337759 C72.869,78.5015768 71.2946667,79.9299585 68.6,79.9299585 C64.576,79.9299585 62.2316667,77.2819917 62.2316667,72.9958506"
            id="Fill-6"
          ></path>
          <path
            d="M82.5023333,74.2854772 L82.5023333,56.1304564 L87.8206667,56.1304564 L87.8206667,60.9042324 L92.231,60.9042324 L92.231,65.4343568 L87.8206667,65.4343568 L87.8206667,73.4141079 C87.8206667,74.6340249 88.3463333,75.2262241 89.5356667,75.2262241 C90.5146667,75.2262241 91.3896667,74.9819087 92.1593333,74.5643154 L92.1593333,78.8152697 C91.0403333,79.4775104 89.746,79.8954357 87.9606667,79.8954357 C84.707,79.8954357 82.5023333,78.606473 82.5023333,74.2854772"
            id="Fill-7"
          ></path>
          <path
            d="M94.5726667,77.1080498 L96.8473333,73.6232365 C98.8766667,75.086805 101.011,75.85361 102.760333,75.85361 C104.3,75.85361 105.000333,75.2959336 105.000333,74.4594191 L105.000333,74.3900415 C105.000333,73.239834 103.180333,72.8560996 101.115667,72.2293776 C98.492,71.4625726 95.5173333,70.2433195 95.5173333,66.6194191 L95.5173333,66.5493776 C95.5173333,62.7512033 98.597,60.6260581 102.376,60.6260581 C104.755,60.6260581 107.344333,61.4270539 109.374333,62.7857261 L107.344333,66.4448133 C105.49,65.3649793 103.635333,64.7024066 102.270667,64.7024066 C100.975667,64.7024066 100.311667,65.2604149 100.311667,65.9917012 L100.311667,66.0617427 C100.311667,67.1073859 102.096333,67.5953527 104.125,68.2917842 C106.75,69.1631535 109.794,70.4175934 109.794,73.8323651 L109.794,73.9020747 C109.794,78.0487967 106.679667,79.9299585 102.621667,79.9299585 C99.9966667,79.9299585 97.057,79.0592531 94.5726667,77.1080498"
            id="Fill-8"
          ></path>
          <path
            d="M42.0346667,22.3551867 C43.552,24.6708714 44.3163333,27.3204979 44.5553333,29.1186722 L47.3256667,29.1186722 C47.0936667,26.9218257 46.203,23.7039004 44.34,20.8577593 C42.123,17.4728631 39.2363333,15.6816598 35.993,15.680332 L35.9733333,15.680332 C33.5,15.680332 31.1843333,16.7873859 29.6196667,18.7180083 C27.976,20.7482158 27.3533333,23.373278 27.912,25.9219917 L30.6003333,25.3357676 C30.2253333,23.6298755 30.66,21.7988382 31.7616667,20.4388382 C32.817,19.1372614 34.3113333,18.4205809 35.974,18.4205809 L35.9883333,18.4205809 C38.858,18.4222407 40.8606667,20.5609959 42.0346667,22.3551867"
            id="Fill-9"
          ></path>
          <path
            d="M62.0323333,41.5521992 C60.9556667,40.0677178 60.1703333,38.2904564 59.8233333,36.5809129 L57.0276667,36.5809129 C57.2426667,37.9160166 57.8123333,40.1195021 59.313,42.4448133 C58.373,44.0119502 56.6846667,45.5595021 54.9003333,46.4849793 C54.899,46.4843154 54.8983333,46.4843154 54.897,46.4836515 C54.8963333,46.4843154 54.8953333,46.4843154 54.8946667,46.4849793 C53.1093333,45.5595021 51.422,44.0119502 50.4816667,42.4448133 C51.9823333,40.1195021 52.5513333,37.9160166 52.7663333,36.5809129 L49.9706667,36.5809129 C49.6243333,38.2904564 48.8393333,40.0677178 47.7623333,41.5521992 L47.2943333,42.1991701 L47.6276667,42.9251452 C48.7963333,45.4629046 51.4933333,48.0082988 54.3403333,49.2577593 L54.8933333,49.5017427 L54.897,49.5004149 L54.901,49.5017427 L55.4536667,49.2577593 C58.3006667,48.0082988 60.998,45.4629046 62.1666667,42.9251452 L62.5,42.1991701 L62.0323333,41.5521992"
            id="Fill-10"
          ></path>
          <path
            d="M67.7596667,22.3551867 C66.2426667,24.6708714 65.4776667,27.3204979 65.2393333,29.1186722 L62.4693333,29.1186722 C62.701,26.9218257 63.591,23.7039004 65.455,20.8577593 C67.671,17.4728631 70.5576667,15.6816598 73.8013333,15.680332 L73.8213333,15.680332 C76.294,15.680332 78.6093333,16.7873859 80.1736667,18.7180083 C81.8183333,20.7482158 82.4406667,23.373278 81.8813333,25.9219917 L79.194,25.3357676 C79.5683333,23.6298755 79.134,21.7988382 78.033,20.4388382 C76.977,19.1372614 75.483,18.4205809 73.82,18.4205809 L73.8056667,18.4205809 C70.936,18.4222407 68.934,20.5609959 67.7596667,22.3551867"
            id="Fill-11"
          ></path>
          <path
            d="M50.0886667,29.1186722 L52.8593333,29.1186722 C52.5136667,25.939917 51.261,23.1704564 50.1516667,20.7166805 C49.043,18.2688797 47.9973333,15.9561826 48.0123333,13.6511203 C48.035,11.0008299 49.5526667,8.29908714 52.0723333,6.42058091 C52.5136667,6.08962656 53.769,5.13029046 54.897,4.09892116 C56.0246667,5.13029046 57.2803333,6.08962656 57.722,6.42058091 C60.2413333,8.29908714 61.759,11.0008299 61.7823333,13.6511203 C61.7966667,15.9561826 60.751,18.2688797 59.643,20.7166805 C58.533,23.1704564 57.281,25.939917 56.9353333,29.1186722 L59.7056667,29.1186722 C60.048,26.4939419 61.117,24.1317842 62.1523333,21.8419917 C63.3866667,19.1140249 64.552,16.5370954 64.5343333,13.6305394 C64.5023333,10.1274689 62.5733333,6.61443983 59.3723333,4.22904564 C58.9953333,3.94456432 57.0316667,2.45477178 55.9033333,1.24414938 L54.897,0.163651452 L53.8913333,1.24414938 C52.7623333,2.45477178 50.7986667,3.94456432 50.4213333,4.22904564 C47.2213333,6.61443983 45.2923333,10.1274689 45.2603333,13.6305394 C45.2423333,16.5370954 46.4083333,19.1140249 47.6413333,21.8419917 C48.677,24.1317842 49.746,26.4939419 50.0886667,29.1186722"
            id="Fill-12"
          ></path>
          <polygon
            id="Fill-13"
            points="39.1796667 34.1629876 70.6143333 34.1629876 70.6143333 31.5362656 39.1796667 31.5362656"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export default ScoutStack;
