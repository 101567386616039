const getResourcesFromStorage = () => {
  const resources = JSON.parse(sessionStorage.getItem('resources'));
  if (resources) {
    return resources;
  }
  return null;
};

const storeResourcesIntoStorage = (rawData) => {
  sessionStorage.setItem('resources', JSON.stringify(rawData));
};

export { getResourcesFromStorage, storeResourcesIntoStorage };
