import React from 'react';
import PropTypes from 'prop-types';

import FullScreen from './FullScreen';

const Layout = ({ layout, ...props }) => {
  switch (layout) {
    case 'fullScreen':
    default:
      return <FullScreen {...props} />;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
