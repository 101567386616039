import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import Icon from '../../Icon';

const BasicInput = ({
  type = 'text',
  name = 'input',
  placeholder = 'Type here...',
  label,
  error,
  value,
  handleChange,
  isOptional,
  helper,
  color,
  w,
  disabled,
  margins = {},
  outline,
  big,
  autoComplete,
  border,
  showSearchIcon,
  showClearIcon,
  searchFunc,
  clearFunc,
  onKeyPress,
  handleBlur,
  ...props
}) => {
  const decideColor = () => {
    if (error) return 'error';
    return color;
  };

  return (
    <CS.Field
      w={w}
      aria-label={label}
      disabled={disabled}
      mb={(margins && margins.mb) || '3'}
      {...margins}
    >
      {!label && helper && (
        <T.Body16 color={decideColor()} m="0" mb="1" ml="1">
          {helper}
        </T.Body16>
      )}
      {label && (
        <CS.Label htmlFor={label}>
          {big ? (
            <T.H2 override="h6" color={decideColor()} m="0" mb="2" ml="1">
              {label}
            </T.H2>
          ) : (
            <T.Body16B color={decideColor()} m="0" mb="1" ml="1">
              {label}
            </T.Body16B>
          )}
          {helper && (
            <T.Body16 color={decideColor()} m="0" mb="1" ml="1">
              {helper}
            </T.Body16>
          )}
          {isOptional && (
            <T.Body16 color="liteGray" m="0" mb="1" ml="1">
              (optional)
            </T.Body16>
          )}
        </CS.Label>
      )}
      <S.Wrapper>
        <S.Input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          color={decideColor()}
          disabled={disabled}
          outline={outline}
          onKeyDown={(e) =>
            e.key === 'Enter' && searchFunc ? searchFunc() : null
          }
          big={big}
          onKeyPress={onKeyPress}
          autoComplete={autoComplete || 'on'}
          showSearchIcon={showSearchIcon}
          aria-label={placeholder}
          {...props}
        />
        {(showSearchIcon || showClearIcon) && (
          <S.IconsWrapper>
            {value && showClearIcon && (
              <S.CloseButton onClick={() => clearFunc && clearFunc()}>
                <Icon icon="close" width="18" height="18" color="gray" />
              </S.CloseButton>
            )}

            {showSearchIcon && (
              <S.SearchButton
                aria-label={placeholder}
                onClick={() => searchFunc && searchFunc()}
              >
                <Icon icon="search" color="gray" />
              </S.SearchButton>
            )}
          </S.IconsWrapper>
        )}
      </S.Wrapper>
      {error && (
        <T.Body16 color="error" m="0" mt="1">
          {error}
        </T.Body16>
      )}
    </CS.Field>
  );
};

export default BasicInput;
