import styled from '@emotion/styled';
import { Link as RLink } from 'react-router-dom';
import FullScreenContent from '../FullScreenContent';

export const Wrapper = styled(RLink)``;

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  flex: 1;
`;

export const StyledFullScreenContent = styled(FullScreenContent)`
  padding-top: ${({ theme }) => theme.spacings[5]};
  padding-bottom: ${({ theme }) => theme.spacings[5]};
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => theme.spacings[3]};
    padding-bottom: ${({ theme }) => theme.spacings[3]};
  }
`;
