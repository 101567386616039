const UserCheck = ({ width, height, color, strokeColor, ...props }) => (
  <svg
    width={width || '29'}
    height={height || '28'}
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8276_42246)">
      <path
        d="M19.1666 24.5V22.1667C19.1666 20.929 18.675 19.742 17.7998 18.8668C16.9246 17.9917 15.7376 17.5 14.5 17.5H6.33329C5.09562 17.5 3.90863 17.9917 3.03346 18.8668C2.15829 19.742 1.66663 20.929 1.66663 22.1667V24.5"
        stroke="#0061CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4167 12.8333C12.994 12.8333 15.0833 10.744 15.0833 8.16667C15.0833 5.58934 12.994 3.5 10.4167 3.5C7.83934 3.5 5.75 5.58934 5.75 8.16667C5.75 10.744 7.83934 12.8333 10.4167 12.8333Z"
        stroke="#0061CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3333 12.8333L22.6666 15.1667L27.3333 10.5"
        stroke="#0061CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8276_42246">
        <rect width="28" height="28" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default UserCheck;
