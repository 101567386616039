const LeftRow = ({ width, height, color, ...props }) => (
  <svg
    width={width || '111'}
    height={height || '32'}
    viewBox="0 0 111 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24.2894L1.99794 21.8917C3.381 23.0355 4.83016 23.7613 6.58612 23.7613C7.96918 23.7613 8.80366 23.2115 8.80366 22.3099V22.2657C8.80366 21.4079 8.27641 20.9679 5.70772 20.3083C2.61219 19.5165 0.614462 18.6587 0.614462 15.6013V15.5575C0.614462 12.7641 2.85375 10.9165 5.99341 10.9165C8.23249 10.9165 10.1426 11.6203 11.7018 12.8741L9.94516 15.4257C8.58406 14.4797 7.24471 13.9079 5.94907 13.9079C4.65448 13.9079 3.97351 14.5017 3.97351 15.2495V15.2935C3.97351 16.3055 4.6321 16.6353 7.28884 17.3169C10.4061 18.1307 12.1621 19.2525 12.1621 21.9357V21.9797C12.1621 25.0372 9.83536 26.7525 6.52024 26.7525C4.19311 26.7525 1.84401 25.939 0 24.2894Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4236 20.7043V20.6601C13.4236 17.2949 15.9921 14.5234 19.5925 14.5234C21.8108 14.5234 23.1935 15.2714 24.2915 16.503L22.2496 18.7027C21.5032 17.9109 20.757 17.4049 19.5711 17.4049C17.9022 17.4049 16.7167 18.8787 16.7167 20.6161V20.6601C16.7167 22.4637 17.88 23.9153 19.7023 23.9153C20.8229 23.9153 21.591 23.4315 22.4031 22.6617L24.3569 24.6413C23.2157 25.8949 21.8983 26.7967 19.5488 26.7967C16.014 26.7967 13.4236 24.0695 13.4236 20.7043Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0105 20.7043V20.6601C25.0105 17.2731 27.7331 14.5234 31.3992 14.5234C35.0439 14.5234 37.7435 17.2293 37.7435 20.6161V20.6601C37.7435 24.0473 35.0213 26.7967 31.3555 26.7967C27.7105 26.7967 25.0105 24.0915 25.0105 20.7043ZM34.451 20.7043V20.6601C34.451 18.9225 33.1997 17.4049 31.3555 17.4049C29.4454 17.4049 28.3037 18.8787 28.3037 20.6161V20.6601C28.3037 22.3977 29.5548 23.9153 31.3992 23.9153C33.3093 23.9153 34.451 22.4415 34.451 20.7043Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0457 22.3759V14.7437H42.3829V21.3203C42.3829 22.9039 43.1294 23.7175 44.4026 23.7175C45.6759 23.7175 46.4882 22.9039 46.4882 21.3203V14.7437H49.8255V26.5329H46.4882V24.8613C45.7198 25.8511 44.7323 26.7527 43.0415 26.7527C40.5168 26.7527 39.0457 25.0813 39.0457 22.3759Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.7644 23.1896V11.73H55.1013V14.7434H57.8682V17.6028H55.1013V22.6396C55.1013 23.4096 55.4309 23.7834 56.1771 23.7834C56.7916 23.7834 57.3406 23.629 57.8235 23.3656V26.0488C57.1212 26.4666 56.3091 26.7306 55.1891 26.7306C53.1475 26.7306 51.7644 25.9168 51.7644 23.1896Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.3376 24.9715L60.7648 22.7719C62.0381 23.6957 63.377 24.1795 64.4746 24.1795C65.4406 24.1795 65.8803 23.8277 65.8803 23.2997V23.2559C65.8803 22.5297 64.7381 22.2877 63.4429 21.8919C61.7967 21.4081 59.9304 20.6385 59.9304 18.3511V18.3069C59.9304 15.9095 61.8624 14.5679 64.2337 14.5679C65.7263 14.5679 67.3507 15.0735 68.6244 15.9313L67.3507 18.2409C66.1875 17.5593 65.0236 17.1411 64.1676 17.1411C63.3551 17.1411 62.9385 17.4931 62.9385 17.9547V17.9989C62.9385 18.6591 64.058 18.9671 65.3308 19.4067C66.9778 19.9567 68.8879 20.7483 68.8879 22.9039V22.9477C68.8879 25.5653 66.9337 26.7527 64.3876 26.7527C62.7406 26.7527 60.8964 26.2031 59.3376 24.9715Z"
      fill={color || 'white'}
    />
    <mask
      id="logo1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="76"
      y="9"
      width="35"
      height="23"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.0718 9.79395H110.185V31.1294H76.0718V9.79395Z"
        fill={color || 'white'}
      />
    </mask>
    <g mask="url(#logo1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.0583 14.0071C86.0105 15.4688 86.49 17.1414 86.64 18.2762H88.378C88.2326 16.8896 87.6738 14.8584 86.5047 13.0619C85.1139 10.9254 83.3027 9.79478 81.2675 9.79395H81.2554C79.7036 9.79395 78.2504 10.4927 77.2689 11.7115C76.2374 12.9928 75.847 14.6497 76.1975 16.2585L77.884 15.8887C77.6487 14.8117 77.9217 13.6559 78.6129 12.7975C79.275 11.976 80.2124 11.5236 81.2558 11.5236H81.2648C83.0653 11.5246 84.3219 12.8746 85.0583 14.0071Z"
        fill={color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.6055 26.1242C96.9299 25.1872 96.4372 24.0654 96.2195 22.9863H94.4656C94.6003 23.829 94.9577 25.2199 95.8993 26.6876C95.3095 27.6768 94.2502 28.6536 93.1309 29.2378C93.13 29.2374 93.1296 29.2374 93.1288 29.2369C93.1284 29.2374 93.1275 29.2374 93.1271 29.2378C92.0071 28.6536 90.9483 27.6768 90.3585 26.6876C91.3 25.2199 91.657 23.829 91.7919 22.9863H90.0379C89.8206 24.0654 89.3278 25.1872 88.6523 26.1242L88.3586 26.5326L88.5678 26.9908C89.3008 28.5926 90.9932 30.1993 92.7795 30.988L93.1263 31.142L93.1288 31.1411L93.1313 31.142L93.4778 30.988C95.2641 30.1993 96.9565 28.5926 97.6898 26.9908L97.8989 26.5326L97.6055 26.1242Z"
        fill={color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.199 14.0071C100.247 15.4688 99.7672 17.1414 99.6176 18.2762H97.8796C98.025 16.8896 98.5834 14.8584 99.7529 13.0619C101.143 10.9254 102.955 9.79478 104.99 9.79395H105.002C106.554 9.79395 108.006 10.4927 108.988 11.7115C110.02 12.9928 110.41 14.6497 110.059 16.2585L108.373 15.8887C108.608 14.8117 108.336 13.6559 107.645 12.7975C106.982 11.976 106.045 11.5236 105.002 11.5236H104.993C103.192 11.5246 101.936 12.8746 101.199 14.0071Z"
        fill={color || 'white'}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.1116 18.2764H91.8502C91.6334 16.27 90.8474 14.5219 90.1514 12.9731C89.4558 11.4282 88.7997 9.96824 88.8091 8.51329C88.8233 6.84043 89.7755 5.13509 91.3565 3.94937C91.6334 3.74047 92.4208 3.13494 93.1287 2.48394C93.8361 3.13494 94.6241 3.74047 94.901 3.94937C96.4817 5.13509 97.4339 6.84043 97.4486 8.51329C97.4576 9.96824 96.8017 11.4282 96.1063 12.9731C95.4099 14.5219 94.6243 16.27 94.4074 18.2764H96.1456C96.3604 16.6197 97.0311 15.1287 97.6807 13.6834C98.4552 11.9615 99.1863 10.3349 99.1753 8.5003C99.1554 6.28916 97.9449 4.07174 95.9365 2.56608C95.6999 2.38651 94.4679 1.44615 93.7601 0.68201L93.1287 0L92.4977 0.68201C91.7894 1.44615 90.5573 2.38651 90.3204 2.56608C88.3128 4.07174 87.1025 6.28916 87.0824 8.5003C87.0709 10.3349 87.8025 11.9615 88.5763 13.6834C89.2261 15.1287 89.8968 16.6197 90.1116 18.2764Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.2671 21.4602H102.99V19.8022H83.2671V21.4602Z"
      fill={color || 'white'}
    />
  </svg>
);

export default LeftRow;
