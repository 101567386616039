import axios from 'axios';
import handleError from './format-error';
import { handleValidationError } from '../validation/validate';

const REQUEST_BASE = '/requests';

const getRequestById = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(`${REQUEST_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getRequestNotes = async ({ id, options } = {}) => {
  try {
    const { data } = await axios.get(`${REQUEST_BASE}/${id}/notes`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const assignRequest = async ({ id, note, action, options } = {}) => {
  try {
    const { data } = await axios.patch(`${REQUEST_BASE}/${id}/assign`, {
      note,
      action,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getRequests = async ({ assignedRequestsOnly, options } = {}) => {
  try {
    const { data } = await axios.get(`${REQUEST_BASE}`, {
      params: { assignedRequestsOnly },
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const requestAChampion = async ({ options, form } = {}) => {
  try {
    const { data } = await axios.post(`${REQUEST_BASE}`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(error.response.data);
      return { error: err, inner };
    }
    return { error: err };
  }
};

export {
  getRequestById,
  getRequestNotes,
  getRequests,
  assignRequest,
  requestAChampion,
};
