const Communication = ({ width, height, color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={width || '150'}
    height={height || '150'}
    {...props}
  >
    <g clipPath="url(#clip0)" fill={color || '#1E1E1E'}>
      <path d="M55.253 42.667a8 8 0 10-11.84 0 9.333 9.333 0 00-4.746 1.653 8 8 0 10-13.44 0 9.333 9.333 0 00-4.64-1.653 8 8 0 10-11.84 0A9.333 9.333 0 000 52v8a1.333 1.333 0 001.333 1.334h16v1.333A1.333 1.333 0 0018.667 64h26.666a1.333 1.333 0 001.334-1.333v-1.334h16A1.333 1.333 0 0064 60v-8a9.333 9.333 0 00-8.747-9.333zM49.333 32a5.334 5.334 0 110 10.667 5.334 5.334 0 010-10.667zM32 34.667a5.333 5.333 0 110 10.666 5.333 5.333 0 010-10.666zM14.667 32a5.333 5.333 0 110 10.667 5.333 5.333 0 010-10.667zm-12 26.667V52a6.667 6.667 0 016.666-6.666c11.507 0 11.814-.227 13.694.733a9.334 9.334 0 00-5.694 8.6v4H2.667zM44 61.333H20v-6.666A6.667 6.667 0 0126.667 48h10.666A6.666 6.666 0 0144 54.667v6.666zm17.333-2.666H46.667v-4a9.333 9.333 0 00-5.694-8.6c1.947-.987 2.28-.733 13.694-.733A6.667 6.667 0 0161.333 52v6.667z" />
      <path d="M7.294 29.133c.853.52 1.013.2 6.36-2.466h21.013A1.333 1.333 0 0036 25.333v-4h14.347c5.467 2.734 5.533 2.974 6.36 2.467.827-.507.627.52.627-22.467A1.333 1.333 0 0056 0H29.334A1.333 1.333 0 0028 1.333v4H8a1.333 1.333 0 00-1.333 1.334c0 22.973-.267 21.906.627 22.466zM30.667 2.667h24v17.84c-3.72-1.84-3.573-1.84-4-1.84h-20v-16zM9.334 8H28v2.667H13.334a1.333 1.333 0 100 2.666H28V20a1.333 1.333 0 001.334 1.333h4V24c-21.747 0-20.187 0-20.6.147l-3.4 1.693V8z" />
      <path d="M34.666 8h16a1.333 1.333 0 000-2.667h-16a1.333 1.333 0 100 2.667zM34.666 14.667h10.667a1.333 1.333 0 100-2.667H34.666a1.333 1.333 0 100 2.667zM24 20a1.333 1.333 0 000-2.667H13.333a1.333 1.333 0 000 2.667H24z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Communication;
