import * as S from './style';
import * as T from '../../components/Typography';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import { navRoutes } from '../../constants';
import { useHistory } from 'react-router-dom';

const Prompt = ({ open, setOpen, userId, updateBookmarkSkill, loading }) => {
  const history = useHistory();

  return (
    open && (
      <Modal open={open} setOpen={setOpen}>
        <S.ModalContent>
          <S.Circle>
            <Icon icon="light" color="blue" width="25" height="25" />
          </S.Circle>
          <T.H2 override="h5" ta="center" mb={5} mt={4}>
            Do you want to bookmark this skill?
          </T.H2>

          {userId ? (
            <>
              <Button
                style={{ marginBottom: 12 }}
                loading={loading}
                onClick={() => {
                  updateBookmarkSkill();
                  setOpen(false);
                }}
              >
                Confirm
              </Button>
              <Button
                mb={1}
                variant="outlined"
                color="liteGray"
                handleClick={() => {
                  history.goBack();
                  setOpen(false);
                }}
              >
                Return to skill area
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ marginBottom: 12 }}
                to={navRoutes.VOLUNTEER.SIGNUP}
              >
                Set up an account to bookmark
              </Button>
              <Button
                mb={1}
                variant="outlined"
                color="liteGray"
                handleClick={() => {
                  history.goBack();
                  setOpen(false);
                }}
              >
                Return to skill area
              </Button>
            </>
          )}
        </S.ModalContent>
      </Modal>
    )
  );
};

export default Prompt;
