const Knowledge = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    width={width || '150'}
    height={height || '150'}
    {...props}
  >
    <g fill={color || '#1E1E1E'} clipPath="url(#clip0)">
      <path d="M40 16h1.334v4a1.334 1.334 0 001.333 1.333H48A1.334 1.334 0 0049.334 20v-2.667a1.333 1.333 0 012.666 0V20a1.333 1.333 0 001.334 1.333H60a1.334 1.334 0 001.334-1.226A18.668 18.668 0 0042.667 0a1.333 1.333 0 00-1.333 1.333V8H40a4 4 0 000 8zm0-5.334h2.667A1.333 1.333 0 0044 9.333V2.667a16 16 0 0114.667 16h-4v-1.334a4 4 0 00-6.813-2.84c-1.334 1.333-1.187 2.8-1.187 4.173H44v-4a1.333 1.333 0 00-1.333-1.333H40a1.334 1.334 0 010-2.667z" />
      <path d="M50.254 29.76c-.52-.56-.52-.427-6.253-.427V28a4 4 0 00-8 0v1.333h-2.667v-4c0-2.466-3.8-.573-4.947-1.746a1.333 1.333 0 01.947-2.254H32A1.333 1.333 0 0033.334 20c0-8.627.147-8.414-.387-8.947a1.335 1.335 0 00-.946-.387c-16.547 0-24.8 20-13.334 31.707 4.134 4.214 4 8.52 4 12.294a9.333 9.333 0 0018.667 0c0-3.227-.307-8 3.787-12a18.52 18.52 0 005.546-11.907 1.332 1.332 0 00-.413-1zM30.667 13.333v5.333h-1.333a4 4 0 00-2.84 6.814c1.333 1.333 2.8 1.186 4.173 1.186v2.667h-4a1.333 1.333 0 00-1.333 1.334v2.666a1.333 1.333 0 11-2.667 0v-2.666a1.333 1.333 0 00-1.333-1.334H16a16 16 0 0114.666-16zM16.227 32h3.774v1.333a4 4 0 108 0V32h2.666v6.666A1.333 1.333 0 0032.001 40h2.666a1.333 1.333 0 110 2.666h-2.666A1.333 1.333 0 0030.667 44v6.666h-5.333c-.867-9.493-7.613-9.813-9.107-18.666zm10.44 26.666h4a1.333 1.333 0 100-2.666h-5.2a12 12 0 01-.133-2.667h13.333c.054.891.01 1.785-.133 2.667H36a1.333 1.333 0 100 2.666h1.333a6.587 6.587 0 01-10.667 0zm12-8h-5.333v-5.333h1.333a4 4 0 002.84-6.813c-1.333-1.334-2.8-1.187-4.173-1.187V32h4a1.333 1.333 0 001.333-1.334V28a1.333 1.333 0 112.667 0v2.666A1.334 1.334 0 0042.667 32h5.107c-1.493 8.96-8.253 8.853-9.107 18.666zM33.334 6.667V1.333a1.333 1.333 0 00-2.667 0v5.334a1.333 1.333 0 002.667 0zM8 29.333H2.667a1.334 1.334 0 000 2.667H8a1.333 1.333 0 100-2.667zM14.08 46.666l-3.76 3.8a1.333 1.333 0 001.88 1.88l3.8-3.773a1.353 1.353 0 00-1.92-1.907zM14.08 14.667A1.353 1.353 0 1016 12.76l-3.8-3.773a1.334 1.334 0 00-1.88 1.88l3.76 3.8zM61.334 29.333H56A1.334 1.334 0 0056 32h5.334a1.333 1.333 0 100-2.667zM49.92 46.666A1.353 1.353 0 0048 48.573c4.093 4.093 4 4.173 4.72 4.173a1.334 1.334 0 00.933-2.28l-3.733-3.8z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Knowledge;
