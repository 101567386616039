const UploadCloud = ({ width, height, color, strokeColor, ...props }) => (
  <svg
    width={width || '40'}
    height={height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_7809_42815)">
      <path
        d="M26.6666 26.6667L20 20L13.3333 26.6667"
        stroke={color || '#404040'}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20V35"
        stroke={color || '#404040'}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9833 30.6501C35.6089 29.7638 36.893 28.3615 37.6331 26.6644C38.3732 24.9673 38.527 23.0721 38.0703 21.2779C37.6137 19.4836 36.5725 17.8926 35.1111 16.7558C33.6498 15.619 31.8514 15.0013 30 15.0001H27.9C27.3955 13.0488 26.4553 11.2373 25.1499 9.70171C23.8446 8.16614 22.2081 6.94647 20.3635 6.1344C18.5189 5.32233 16.5142 4.93899 14.5002 5.01319C12.4861 5.0874 10.5151 5.61722 8.73529 6.56283C6.95547 7.50844 5.41318 8.84523 4.22436 10.4727C3.03555 12.1002 2.23114 13.9759 1.87163 15.959C1.51211 17.9421 1.60684 19.9809 2.14868 21.9222C2.69053 23.8634 3.6654 25.6565 5 27.1667"
        stroke={color || '#404040'}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6666 26.6667L20 20L13.3333 26.6667"
        stroke={color || '#404040'}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default UploadCloud;
